
import { Avatar, Form, Input, message, Modal, Radio, Select, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ImageUpload from '../../../../../common/components/imageUpload/ImageUpload';
import { Speaker, SpecificMembersTargeting, TargetAudience } from '../../../../../common/models/EventModel';
import UserModel, { UserPublicData } from '../../../../../common/models/UserModel';
import MembersStore from '../../../../../store/MembersStore';
import styles from './TargetingModal.module.css';
import debounce from 'lodash/debounce';
import { RadioChangeEvent } from 'antd/lib/radio';

interface Props {
    visible: boolean;
    onCreateOrUpdate: (target: TargetAudience, edit: boolean) => void;
    target?: TargetAudience
    onCancel: () => void;
    membersStore?: MembersStore;
}

function TargetingModal({ membersStore, visible, onCancel, onCreateOrUpdate, target }: Props) {
    const [form] = Form.useForm();
    const [targetType, setTargetType] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [fetchedUsers, setFetchedUsers] = useState(Array<UserPublicData>());
    const [selectedMembers, setSelectedMembers] = useState(Array<UserPublicData>());

    useEffect(() => {
        if (target) {
            setEditMode(true);
            setTargetType(target.type)
            if(target.type === 'members') {
                setSelectedMembers(target.members || []);
                form.setFieldsValue({
                    type: 'members',
                    members: target.members.map(m => m?.id)
                })
            }
            
            if(target.type === 'cohorts') {
                form.setFieldsValue({
                    type: 'cohorts',
                    cohorts: target.cohorts.join(',')
                })
            }
           
        }
    }, [target])

    const onTargetTypeChanged = (e: RadioChangeEvent) => {
        const targetType = e.target.value;
        debugger;
        setTargetType(targetType);
    }

    const fetchUser = async (value: string) => {
        setFetching(true);
        const result = await membersStore!.fetchByName(value)
        setFetchedUsers(result.map(u => u.toPublicData()));
        setFetching(false);
    }

    const onSelect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers, user]);
    }

    const onDeselect = (value: string, option: any) => {
        const user = option.extra;
        setSelectedMembers([...selectedMembers.filter(m => m.id !== user.id)]);
    }

    const getOptions = () => {
        if (fetchedUsers.length > 0) {
            return fetchedUsers;
        }

        return selectedMembers;
    }

    const onConfirm = async () => {
        const values = await form.validateFields();

        const { type, cohorts } = values;

        if(type === 'members') {
            if (!selectedMembers) {
                message.error('Please select members to target')
                return;
            }

            onCreateOrUpdate({ type: type, members: selectedMembers }, editMode)
        }

        if(type === 'cohorts') { 
            const selectedCohorts = (cohorts as string).replaceAll(' ', '').split(',');
            onCreateOrUpdate({ type: type, cohorts: selectedCohorts }, editMode)
        }

        form.resetFields();
    }

    return (
        <Modal
            visible={visible}
            title={editMode ? 'Edit Target Audience' : 'Add Target Audience'}
            okText={editMode ? 'Update' : 'Add'}
            cancelText="Cancel"
            centered
            className={'event-targeting-modal'}
            onCancel={onCancel}
            onOk={onConfirm}
        >
            <Form
                form={form}
                layout="vertical"
                name="addTargetForm"
            >
                <Form.Item label="Select targeting type"
                    rules={[{ required: true, message: `Please select an option` }]}
                    name="type">
                    <Radio.Group size='large' onChange={onTargetTypeChanged}>
                        <Radio.Button value={'members'}>Specfic Members</Radio.Button>
                        <Radio.Button value={'cohorts'}>Cohorts</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {targetType === 'members' &&
                    <Form.Item
                        name="members"
                        style={{ marginTop: 54 }}
                        label='Target Members'
                        tooltip={'Select specific members who can see this event.'}>
                        <Select
                            mode="multiple"
                            allowClear
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            onSearch={debounce(fetchUser, 600)}
                            size={'large'}
                            className={styles.userSelect}>
                            {
                                getOptions().map(user => {
                                    return <Select.Option extra={user} value={user.id!} key={user.id}>
                                        <div className={styles.attendeeOption}>
                                            <Avatar className={styles.attendeeAvatar} src={user.photoUrl} />
                                            <div className={styles.attendeeName}>{user.firstName} {user.lastName}</div>
                                        </div>
                                    </Select.Option>

                                })
                            }
                        </Select>
                    </Form.Item>
                }
                {
                    targetType === 'cohorts' && 
                    <>
                        <div>{ 'Write the cohorts who can see this event (seprated by commas) '}</div>
                        <Form.Item
                        name="cohorts"
                        style={{ marginTop: 54 }}
                        label='Cohorts'
                        tooltip={'Write down specific cohorts who can see this event.'}>
                            <Input placeholder='TTEF1, TTEF2' />
                        </Form.Item>
                    </>
                }
            </Form>
        </Modal>)
}

export default inject('membersStore')(observer(TargetingModal));