import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './UpcomingPage.module.css'
import SessionStore from '../../store/SessionStore';
import { Button } from 'antd';
import FoundingMemberCard from './foundingMember/FoundingMemberCard';
import Lottie from 'react-lottie';

interface Props {
    sessionStore: SessionStore
}


function UpcomingPage({ sessionStore }: Props) {
    const { authUser } = sessionStore;

    if (!sessionStore.isAuthed) {
        return <Redirect to='/login' />
    }

    return (
        <div className={styles.container}>
            {/* <div className={styles.animation}>
                <Lottie options={lottieFile} />
            </div> */}
            <div className={styles.header}>
                Boarding gate will open soon<span style={{ color: '#1CBEC0' }}>.</span>
            </div>
            <div className={styles.subHeader}>Don't worry, we'll let you know when it happens.</div>
            <FoundingMemberCard className={styles.foundingMemberCard} />
            <div className={styles.logout}>
                Not {authUser?.firstName ? authUser?.firstName : authUser?.email}? <a onClick={sessionStore.logout}>Logout.</a>
            </div>
        </div>
    )
}

export default inject('sessionStore')(observer(UpcomingPage));