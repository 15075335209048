import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { } from '@ant-design/icons';
import styles from './EventRedirectPage.module.css';
import SessionStore from "../../../../../store/SessionStore";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import EventsStore from "../../../../../store/EventsStore";
import EventModel from "../../../../../common/models/EventModel";
import { message } from "antd";
import SettingsStore from "../../../../../store/SettingsStore";
import Loader from "../../../../../common/components/loader/Loader";
import TimeUtils from "../../../../../common/utils/TimeUtils";
import spatialImage from './spatial-login-min.jpeg';
import Lottie from "react-lottie";
import loadingAnimation from './loading.json'
import handAnimation from './hand-animation2.json'

const SECONDS_TO_REDIRECT = TimeUtils.getSeconds(5);

interface Props {
    sessionStore: SessionStore;
    eventsStore: EventsStore;
    settingsStore: SettingsStore;
}

function EventRedirectPage({ sessionStore, eventsStore, settingsStore }: Props) {
    const { sessionFetched, authUser, loggedIn } = sessionStore;
    const { space, terminology } = settingsStore;
    const [event, setEvent] = useState<EventModel>()
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const [completeTextVisible, setCompleteTextVisible] = useState(false);
    const [countdown, setCountdown] = useState(SECONDS_TO_REDIRECT);

    useEffect(() => {
        const skipLogin = new URLSearchParams(location.search).get("skip");

        if (!sessionFetched) {
            return;
        }

        if (!loggedIn && !skipLogin) {
            history.push({ pathname: '/login', state: { from: location.pathname } })
            return;
        }

        const { eventId } = match.params as any;
        eventsStore.getEvent(eventId).then(event => {
            setEvent(event);
        }).catch(error => {
            message.error('Something went wrong!');
        });

    }, [sessionFetched, loggedIn]);

    useEffect(() => {
        if (!event) {
            return;
        }

        eventsStore.reportAttended(event);

        const interval = setInterval(() => {
            setCountdown(prev => prev !== 0 ? prev - 1000 : prev)
        }, 1000)

        setTimeout(() => {
            setCompleteTextVisible(true);
        }, SECONDS_TO_REDIRECT - TimeUtils.getSeconds(2));

        setTimeout(() => {
            if(event.eventPlatform === 'external') {
                window.location.href = event.externalLink!;
            } else {
                window.location.href = `${window.location.protocol}//${window.location.host}/space/${event?.shortId}`
            }
            
        }, SECONDS_TO_REDIRECT);

        return () => {
            clearInterval(interval);
        }
    }, [event]);

    const helpedClicked = () => {
        window.open('https://tawk.to/chat/600c71d5c31c9117cb71c037/1eso9pao3', '_blank');
    }

    return (<div className={styles.container}>
        <div className={styles.header}>
            <div className={styles.headerContent}>
                <img src={space?.logo} className={styles.logo} />
                <div className={styles.helpButton} onClick={helpedClicked}>
                    <span className={'icon-ic-help'}></span> <span>Get Help</span>
                </div>

            </div>

        </div>
        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                <div className={styles.leftSide}>
                    <div className={styles.loadingAnimation}>
                        <Lottie options={lottieFile} />
                    </div>
                    {!completeTextVisible &&
                        <div className={`${styles.mainText} animate__animated animate__fadeInUp`}>You're being redirected to the {terminology?.eventTerm}...</div>
                    }
                    {completeTextVisible &&
                        <div className={`${styles.mainText} animate__animated animate__fadeInUp`}>Getting you in...</div>
                    }
                </div>
                {/* <div className={`${styles.infoBubble} animate__animated animate__fadeIn animate__delay-3s`}>
                    <div className={styles.infoTextContainer}>
                        <div className={styles.infoText}>Please choose <b>Continue as Guest</b> on the next page (if asked)</div>
                    </div>
                    <div className={styles.imageContainer}>
                        <div className={styles.handAnimation}>
                            <Lottie options={lottieHand} />
                        </div>
                        <img className={styles.spatialImage} src={spatialImage} />

                    </div>
                </div> */}
            </div>
        </div>
    </div>);
}

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const lottieHand = {
    loop: true,
    autoplay: true,
    animationData: handAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


export default inject('sessionStore', 'eventsStore', 'settingsStore')(observer(EventRedirectPage));