import React, { useState } from 'react';
import Avatar from '../../../common/components/avatar/UserAvatar';
import styles from './FoundingMemberCard.module.css';
import barcode from './barcode.png';
import { inject, observer } from 'mobx-react';
import SessionStore from '../../../store/SessionStore';


interface Props {
    className?: string,
    sessionStore?: SessionStore,
}

function FoundingMemberCard({ sessionStore, className }: Props) {
    const { authUser } = sessionStore!;

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.boardingPassHeader}>
                <div className={styles.boardingPassText}>Boarding Pass</div>
            </div>
            <div className={styles.dashed}>
                <div className={styles.mockingContainer}>
                    <div className={styles.mockingHeader}>
                        Flight
                    </div>
                    <div className={styles.mockingText} />
                    <div className={styles.mockingHeader} style={{ marginTop: 8}}>
                        Gate
                    </div>
                    <div className={styles.mockingText} style={{ width: 30  }} />
                    <img src={barcode} className={styles.barcode} />
                </div>
            </div>
            <div className={styles.content}>
                <Avatar userImage={authUser?.photoUrl} companyImage={authUser?.company?.logoUrl} />
                <div className={styles.textContainer}>
                    <div className={styles.header}>{authUser?.fullName}</div>
                    <div className={styles.secondaryText}><span style={{ fontWeight: 600 }}>Membership Card</span></div>
                </div>
            </div>

        </div>
    )
}

export default inject('sessionStore')(observer(FoundingMemberCard));