
import { Button } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../common/components/card/Card';
import EventModel from '../../../../../common/models/EventModel';
import AddToCalendar from '../addToCalendar/AddToCalendar';
import LiveBadge from '../liveBadge/LiveBadge';
import styles from './EventPromo.module.css';
import { VideoCameraOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import SettingsStore from '../../../../../store/SettingsStore';
import _ from 'lodash';

interface Props {
    className?: string,
    startDate?: number,
    endDate?: number,
    isActive: boolean,
    isEnded: boolean,
    event?: EventModel
    backstageAccess?: boolean,
    hideAddToCalendar?: boolean,
    isDemoEvent?: boolean,
    joinEvent: () => void,
    settingsStore?: SettingsStore;
}

function EventPromo({ event, startDate, endDate, isActive, isEnded, joinEvent, className, backstageAccess, isDemoEvent = false, hideAddToCalendar, settingsStore }: Props) {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: '',
        seconds: '',
    });

    const { terminology } = settingsStore!;

    const history = useHistory();

    useEffect(() => {
        updateCountdown();

        const interval = setInterval(() => {
            updateCountdown();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getTitles = () => {
        if (isDemoEvent) {
            return {
                header: `We're live - join us!`,
                subTitle: `The ${terminology?.eventTerm} has started and you're welcomed to join.`
            }
        }

        if (isEnded) {
            return {
                header: `${_.capitalize(terminology?.eventTerm)} has ended`,
                subTitle: `It was fun. See you next time!`
            }
        }

        if (isActive) {
            return {
                header: `We're live - join us!`,
                subTitle: `The ${terminology?.eventTerm} has started and you're welcomed to join.`
            }
        } else {
            return {
                header: `The ${terminology?.eventTerm} hasn’t started yet`,
                subTitle: `You’ll be able to join just before it starts.`
            }
        }
    }

    const updateCountdown = () => {
        const then = moment.unix(startDate || 0);
        const now = moment();
        const countdown = moment.unix(then.diff(now, 'seconds'));
        const days = then.diff(now, 'days');
        const hours = then.diff(now, 'hours') - (days * 24);
        const minutes = countdown.format('mm');
        const seconds = countdown.format('ss');

        setCountdown({ days, hours, minutes: minutes, seconds: seconds })
    }

    const enterBackstage = () => {
        history.push(`/live/${event?.shortId}`, { event: event! });
    }

    const openRecord = () => {
        window.open(event?.recordUrl, '_blank');
    }

    const titles = getTitles();

    return (
        <Card className={`${styles.container} ${className}`}>
            <div className={styles.overlay}>
                {(isActive || isDemoEvent) && <LiveBadge style={{ marginBottom: 12 }} />}
                <div className={styles.header}>
                    {titles.header}
                </div>
                <div className={styles.subHeader}>
                    {titles.subTitle}
                </div>
                {isActive &&
                    <Button onClick={joinEvent} type='primary' size='large' className={`${styles.joinButton} animate__animated animate__pulse animate__infinite animate__slow`}>
                        Join Now
                    </Button>
                }
                {
                    isEnded && event?.recordUrl &&
                    <Button icon={<VideoCameraOutlined />} onClick={openRecord} type='primary' size='large' className={`${styles.recordingButton}`}>
                        Watch Recording
                    </Button>
                }
                {!isActive && !isEnded &&
                    <div>
                        <div className={styles.countdownWrapper}>
                            <div className={styles.countdownItemContainer}>
                                <div className={styles.countdownItem}>
                                    {countdown.days < 10 ? '0' + countdown.days : countdown.days}
                                </div>
                                <div className={styles.countdownTimeDescription}>days</div>
                            </div>
                            <div className={styles.countdownItemContainer}>
                                <div className={styles.countdownItem}>
                                    {countdown.hours < 10 ? '0' + countdown.hours : countdown.hours}
                                </div>
                                <div className={styles.countdownTimeDescription}>hours</div>
                            </div>
                            <div className={styles.countdownItemContainer}>
                                <div className={styles.countdownItem}>
                                    {countdown.minutes}
                                </div>
                                <div className={styles.countdownTimeDescription}>
                                    mintues
                                </div>
                            </div>
                            <div className={styles.countdownItemContainer}>
                                <div className={styles.countdownItem}>
                                    {countdown.seconds}
                                </div>
                                <div className={styles.countdownTimeDescription}>
                                    seconds
                                </div>
                            </div>
                        </div>
                        {!hideAddToCalendar &&
                            <AddToCalendar className={styles.addToCalendar} event={event}>
                                <div>📅 Add to calendar</div>
                            </AddToCalendar>
                        }
                    </div>
                }
                {backstageAccess &&
                    <div className={styles.speakerAccess}>
                        <Button onClick={enterBackstage} type='default' size='small' className={styles.backstageButton}>
                            Enter backstage
                        </Button>
                    </div>
                }
            </div>
        </Card>)

}

export default inject('settingsStore')(observer(EventPromo));