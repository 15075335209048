import { Alert, Avatar, Button, Form, Input, InputNumber, List, message, Modal, notification, Radio, Select, Skeleton, Switch, Tabs, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import SessionStore from '../../../../store/SessionStore';
import { ControlPanelHeader } from '../ControlPanel';
import styles from './SettingsTab.module.css';
import HeaderWithDescription from '../../../../common/components/headerWithDescription/HeaderWithDescription';
import SettingsStore from '../../../../store/SettingsStore';
import { useForm } from 'antd/lib/form/Form';
import ImageUpload from '../../../../common/components/imageUpload/ImageUpload';
import { RadioChangeEvent } from 'antd/lib/radio';
const { Option } = Select;
interface Props {
    controlPanelStore?: ControlPanelStore;
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
}

function SettingsTab({ sessionStore, settingsStore }: Props) {
    const { membership, space, email, miscellaneous, terminology, access } = settingsStore!;
    const { authUser } = sessionStore!;
    const [busy, setBusy] = useState(false);
    const [membershipForm] = useForm();

    const [generalForm] = useForm();
    const [generalFormButtonEnabled, setGeneralFormButtonEnabled] = useState(false);
    const [logoUrl, setLogoUrl] = useState('');
    const [smallLogoUrl, setSmallLogoUrl] = useState('');
    const [squareLogoUrl, setSquareLogoUrl] = useState('');

    const [emailSettingsForm] = useForm();
    const [emailsFormButtonEnabled, setEmailsFormButtonEnabled] = useState(false);
    const [senderPhotoUrl, setSenderPhotoUrl] = useState('');

    const [miscellaneousForm] = useForm();
    const [miscellaneousFormButtonEnabled, setMiscellaneousFormButtonEnabled] = useState(false);

    const [terminologyForm] = useForm();
    const [terminologyFormButtonEnabled, setTerminologyFormButtonEnabled] = useState(false);

    const [accessForm] = useForm();
    const [accessFormButtonEnabled, setAccessFormButtonEnabled] = useState(false);
    const [accessLevel, setAccessLevel] = useState('public');


    useEffect(() => {
        if (space) {
            generalForm.setFieldsValue({
                name: space?.name,
                description: space?.description,
                websiteUrl: space?.websiteUrl,
                primaryColor: space?.primaryColor,
                primaryDarkColor: space?.primaryDarkColor,
                primaryLightColor: space?.primaryLightColor,
            })

            setLogoUrl(space?.logo || '');
            setSmallLogoUrl(space?.smallLogo || '');
            setSquareLogoUrl(space?.squareLogo || '');
        }
    }, [space])

    useEffect(() => {
        if (membership) {
            membershipForm.setFieldsValue({
                membershipEnabled: membership?.enabled,
                eventCredits: membership?.defaultEventCredits,
                introCredits: membership?.defaultIntroCredits,
            })
        }
    }, [membership]);

    useEffect(() => {
        if (email) {
            emailSettingsForm.setFieldsValue({
                senderEmail: email?.senderEmail,
                senderName: email?.senderName,
                emailVerified: email?.emailVerified,
            });

            setSenderPhotoUrl(email.senderPhotoUrl || '');
        }
    }, [email]);


    useEffect(() => {
        if (miscellaneous) {
            miscellaneousForm.setFieldsValue({
                spatialLink: miscellaneous?.spatialDefaultUrl,
                zoomWebinarId: miscellaneous?.zoomWebinarId,
                discordNotificationsChannel: miscellaneous?.discordNotificationsChannel,
                chatLink: miscellaneous?.chatLink,
                cohort: miscellaneous?.currentCohort
            })
        }
    }, [miscellaneous]);

    useEffect(() => {
        if (terminology) {
            terminologyForm.setFieldsValue({
                eventTerm: terminology?.eventTerm,
            })
        }
    }, [terminology]);

    useEffect(() => {
        if (access) {
            accessForm.setFieldsValue({
                accessLevel: access?.accessLevel,
                requestAccessUrl: access?.requestAccessUrl,
            })

            setAccessLevel(access?.accessLevel || 'public')
        }
    }, [access]);

    const onGeneralFormSubmitted = async (values: any) => {
        if (!logoUrl || !smallLogoUrl || !squareLogoUrl) {
            message.info('Please add missing logos');
            return;
        }
        setBusy(true);
        await settingsStore?.updateSettings({
            space: {
                name: values.name,
                description: values.description,
                websiteUrl: values.websiteUrl,
                primaryColor: values.primaryColor,
                primaryLightColor: values.primaryLightColor,
                primaryDarkColor: values.primaryDarkColor,
                logo: logoUrl || undefined,
                smallLogo: smallLogoUrl || undefined,
                squareLogo: squareLogoUrl || undefined,
            }
        })
        setBusy(false);
        setGeneralFormButtonEnabled(false);
        message.success('Settings updated!')
    }

    const onGeneralFormChanged = () => {
        setGeneralFormButtonEnabled(true);
    }

    const onLogoUploadFinished = (uploadUrl: string) => {
        setLogoUrl(uploadUrl);
        setGeneralFormButtonEnabled(true);
    }

    const onSmallLogoUploadFinished = (uploadUrl: string) => {
        setSmallLogoUrl(uploadUrl);
        setGeneralFormButtonEnabled(true);
    }

    const onSquareLogoUploadFinished = (uploadUrl: string) => {
        setSquareLogoUrl(uploadUrl);
        setGeneralFormButtonEnabled(true);
    }

    const onSenderPhotoUploadFinished = (uploadUrl: string) => {
        setSenderPhotoUrl(uploadUrl);
        setEmailsFormButtonEnabled(true);
    }

    const onEmailSettingsFormSubmitted = async (values: any) => {
        if (!senderPhotoUrl) {
            message.info('Please add email sender photo');
            return;
        }

        setBusy(true);
        await settingsStore?.updateSettings({
            email: {
                senderName: values.senderName,
                senderEmail: values.senderEmail,
                senderPhotoUrl: senderPhotoUrl || undefined,
                emailVerified: values.emailVerified,
            }
        })
        setBusy(false);
        setEmailsFormButtonEnabled(false);
        message.success('Email settings updated!')
    }

    const onEmailSettingsFormChanged = () => {
        setEmailsFormButtonEnabled(true);
    }

    const onMiscellaneousFormFormChanged = () => {
        setMiscellaneousFormButtonEnabled(true);
    }

    const onMiscellaneousFormFormSubmitted = async (values: any) => {
        setBusy(true);
        await settingsStore?.updateSettings({
            miscellaneous: {
                spatialDefaultUrl: values.spatialLink,
                zoomWebinarId: values.zoomWebinarId,
                discordNotificationsChannel: values.discordNotificationsChannel,
                telegramNotificationsChannel: values.telegramNotificationsChannel,
                chatLink: values.chatLink,
                currentCohort: values.cohort,
            }
        })
        setBusy(false);
        setMiscellaneousFormButtonEnabled(false);
        message.success('Settings updated!')
    }

    const onMembershipEnabledChanged = async (value: boolean) => {
        setBusy(true);
        await settingsStore?.enableMembership(value);
        message.success('Settings were updated!')
        setBusy(false);
    }

    const onEventsCreditChanged = async (value: number | string | undefined) => {
        setBusy(true);
        await settingsStore?.updateSettings({
            membership: {
                defaultEventCredits: value,
            }
        });

        message.success('Trial Credits were updated!')
        setBusy(false);
    }

    const onIntroCreditChanged = async (value: number | string | undefined) => {
        setBusy(true);
        await settingsStore?.updateSettings({
            membership: {
                defaultIntroCredits: value,
            }
        });

        message.success('Trial Credits were updated!')
        setBusy(false);
    }

    const onTerminologyFormFormChanged = () => {
        setTerminologyFormButtonEnabled(true);
    }

    const onTerminologyFormFormSubmitted = async (values: any) => {
        setBusy(true);
        await settingsStore?.updateSettings({
            terminology: {
                eventTerm: values.eventTerm,
            }
        })
        setBusy(false);
        setTerminologyFormButtonEnabled(false);
        message.success('Settings updated!')
    }


    const onAccessFormChanged = () => {
        setAccessFormButtonEnabled(true);
    }

    const onAccessFormSubmitted = async (values: any) => {
        setBusy(true);
        await settingsStore?.updateSettings({
            access: {
                accessLevel: values.accessLevel,
                requestAccessUrl: values.requestAccessUrl
            }
        })

        setBusy(false);
        setAccessFormButtonEnabled(false);
        message.success('Settings updated!')
    }

    const onAccessChanged = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setAccessLevel(value);
    }


    return (
        <div className={styles.container}>
            <div className={styles.sectionsContainer}>
                <Card>
                    <Form form={generalForm}
                        onFieldsChange={onGeneralFormChanged}
                        onFinish={onGeneralFormSubmitted}>
                        <ControlPanelHeader
                            title={'General'}
                            description={'Manage your space branding and appearance.'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Space Name</div>
                            <Form.Item name={'name'}
                                rules={[{ required: true, message: 'Please add a value' }]}>
                                <Input style={{ maxWidth: 300 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Website Link</div>
                            <Form.Item
                                name='websiteUrl'
                                rules={[{ required: true, type: 'url', message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Description</div>
                            <Form.Item
                                name='description'
                                rules={[{ required: true, message: 'Please add a value' }]}>
                                <Input.TextArea maxLength={200} minLength={50} showCount />
                            </Form.Item>
                        </div>
                        <div className={`${styles.setting}`}>
                            <div className={styles.subHeader}>Colors</div>
                            <div className={styles.threeItemsSetting}>
                                <Form.Item
                                    label='Primary Color'
                                    name='primaryColor'
                                    rules={[{ required: true, message: 'Please add a value' }]}>
                                    <Input style={{ width: 60 }} type='color' />
                                </Form.Item>
                                <Form.Item
                                    label='Primary Light Color'
                                    name='primaryLightColor'
                                    rules={[{ required: true, message: 'Please add a value' }]}>
                                    <Input style={{ width: 60 }} type='color' />
                                </Form.Item>
                                <Form.Item
                                    label='Primary Dark Color'
                                    name='primaryDarkColor'
                                    rules={[{ required: true, message: 'Please add a value' }]}>
                                    <Input style={{ width: 60 }} type='color' />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={styles.settings}>
                            <div className={styles.subHeader}>Logos</div>
                            <div className={styles.threeItemsSetting}>
                                <Form.Item
                                    name="logo"
                                    label="Main Logo">
                                    <ImageUpload previousImage={logoUrl}
                                        imageStyle={{ objectFit: 'contain' }}
                                        prefix='space_logo'
                                        onUploadFinish={onLogoUploadFinished} />
                                </Form.Item>
                                <Form.Item
                                    name="smallLogo"
                                    label="Small Logo">
                                    <ImageUpload previousImage={space?.smallLogo}
                                        prefix='space_small_logo'
                                        imageStyle={{ objectFit: 'contain' }}
                                        onUploadFinish={onSmallLogoUploadFinished} />
                                </Form.Item>
                                <Form.Item
                                    name="squareLogo"
                                    label="Square Logo">
                                    <ImageUpload previousImage={squareLogoUrl}
                                        prefix='space_square_logo'
                                        imageStyle={{ objectFit: 'contain' }}
                                        onUploadFinish={onSquareLogoUploadFinished} />
                                </Form.Item>

                            </div>

                        </div>
                        {generalFormButtonEnabled &&
                            <Button loading={busy} size="large" htmlType='submit' type='primary'>
                                Save Settings
                            </Button>
                        }
                    </Form>
                </Card>
                <Card>
                    <Form form={accessForm}
                        onFieldsChange={onAccessFormChanged}
                        onFinish={onAccessFormSubmitted}>
                        <ControlPanelHeader
                            title={'Community Access'}
                            description={'Control who can join your community.'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Access Level</div>
                            <Form.Item
                                rules={[{ required: true, message: `Please select an option` }]}
                                name="accessLevel">

                                <Radio.Group size='large' defaultValue='public' onChange={onAccessChanged}>
                                    <Radio.Button value={'public'}>Public</Radio.Button>
                                    <Radio.Button value={'invite-only'}>Invite Only</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        {accessLevel === 'invite-only' &&
                            <div className={styles.setting}>
                                <div className={styles.subHeader}>Website Link</div>
                                <Form.Item
                                    name='requestAccessUrl'
                                    rules={[{ required: true, type: 'url', message: 'Please add a value' }]}>
                                    <Input placeholder='https://' style={{ maxWidth: 500 }} />
                                </Form.Item>
                            </div>
                        }
                        {accessFormButtonEnabled &&
                            <Button loading={busy} size="large" htmlType='submit' type='primary'>
                                Save Settings
                            </Button>
                        }
                    </Form>
                </Card>
                <Card>
                    <Form form={emailSettingsForm}
                        onFieldsChange={onEmailSettingsFormChanged}
                        onFinish={onEmailSettingsFormSubmitted}>
                        <ControlPanelHeader
                            title={'Email Settings'}
                            description={'Manage which email and sender information is used to communicate with members.'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Primary Email</div>
                            <Form.Item name={'senderEmail'}
                                rules={[{ required: true, type: 'email', message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Sender Name</div>
                            <Form.Item name={'senderName'}
                                rules={[{ required: true, message: 'Please add a value' }]}>
                                <Input style={{ maxWidth: 300 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Sender Photo</div>
                            <Form.Item
                                name="senderPhotoUrl">
                                <ImageUpload previousImage={email?.senderPhotoUrl}
                                    prefix='sender_photo'
                                    imageStyle={{ objectFit: 'contain' }}
                                    onUploadFinish={onSenderPhotoUploadFinished} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Verified Email Account</div>
                            <Form.Item
                                name="emailVerified"
                                valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                        </div>

                        {emailsFormButtonEnabled &&
                            <Button loading={busy} size="large" htmlType='submit' type='primary'>
                                Save Settings
                            </Button>
                        }
                    </Form>
                </Card>
                <Card>
                    <Form form={membershipForm}>
                        <ControlPanelHeader
                            title={'Membership'}
                            description={'Determine wether members will have to pay for using the platform.'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Is Enabled?</div>
                            <Form.Item name={'membershipEnabled'}
                                valuePropName='checked'>
                                <Switch

                                    onChange={onMembershipEnabledChanged}
                                    loading={busy}
                                    size='default'
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Trial Credits</div>
                            <div className={styles.sideBySide}>
                                <div>
                                    <div className={styles.label}>Events:</div>
                                    <Form.Item name='eventCredits'>
                                        <InputNumber className={styles.input} min={0} max={10} onChange={onEventsCreditChanged} />
                                    </Form.Item>
                                </div>
                                <div>
                                    <div className={styles.label}>Intro Requests:</div>
                                    <Form.Item name='introCredits'>
                                        <InputNumber className={styles.input} min={0} max={10} onChange={onIntroCreditChanged} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card>
                <Card>
                    <Form form={terminologyForm}
                        onFieldsChange={onTerminologyFormFormChanged}
                        onFinish={onTerminologyFormFormSubmitted}>
                        <ControlPanelHeader
                            title={'Terminology'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Event Term</div>
                            <Form.Item name={'eventTerm'}
                                rules={[{ required: true, message: 'Please select a value' }]}>
                                <Select defaultValue="event" style={{ width: 120 }}>
                                    <Option value="event">Event</Option>
                                    <Option value="meeting">Meeting</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        {terminologyFormButtonEnabled &&
                            <Button loading={busy} size="large" htmlType='submit' type='primary'>
                                Save Changes
                            </Button>
                        }
                    </Form>
                </Card>
                <Card>
                    <Form form={miscellaneousForm}
                        onFieldsChange={onMiscellaneousFormFormChanged}
                        onFinish={onMiscellaneousFormFormSubmitted}>
                        <ControlPanelHeader
                            title={'Miscellaneous'}
                        />
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Spatial Link</div>
                            <Form.Item name={'spatialLink'}
                                rules={[{ required: true, type: 'url', message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Zoom Webinar ID</div>
                            <Form.Item name={'zoomWebinarId'}
                                rules={[{ required: false, message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Discord Notifications Channel ID</div>
                            <Form.Item name={'discordNotificationsChannel'}
                                rules={[{ required: false, message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Telegram Notifications Channel ID</div>
                            <Form.Item name={'telegramNotificationsChannel'}
                                rules={[{ required: false, message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Chat Invite Link</div>
                            <Form.Item name={'chatLink'}
                                rules={[{ required: false, message: 'Please add a value' }]}>
                                <Input disabled={!authUser?.kapara} style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        <div className={styles.setting}>
                            <div className={styles.subHeader}>Current Cohort</div>
                            <Form.Item name={'cohort'}
                                rules={[{ required: false, message: 'Please add a value' }]}>
                                <Input style={{ maxWidth: 500 }} />
                            </Form.Item>
                        </div>
                        {miscellaneousFormButtonEnabled &&
                            <Button loading={busy} size="large" htmlType='submit' type='primary'>
                                Save Changes
                            </Button>
                        }
                    </Form>
                </Card>

            </div>


        </div>
    )
}

export default inject('settingsStore', 'sessionStore')(observer(SettingsTab));