import UserModel, { Presence } from "./UserModel";

class AttendeeModel {
    id?: string;
    user?: UserModel;
    presence?: Presence;
    attended?: boolean;
    role?: AttendeeRole;
    notifyEntrance?: boolean;
    status?: AttendeeStatus;
    firstTimer?: boolean;
    feedbackSubmitted?: boolean;
    eventId?: string;
    waitingApproval?: boolean; 

    constructor(id?: string,
        user?: UserModel,
        presence?: Presence,
        attended?: boolean,
        status?: AttendeeStatus,
        notifyEntrance?: boolean,
        role?: AttendeeRole,
        firstTimer?: boolean,
        feedbackSubmitted?: boolean,
        eventId?: string,
        waitingApproval?: boolean) {
        this.id = id;
        this.user = UserModel.mapFromServer(user);
        this.presence = presence;
        this.attended = attended;
        this.status = status;
        this.notifyEntrance = notifyEntrance;
        this.role = role;
        this.firstTimer = firstTimer;
        this.feedbackSubmitted = feedbackSubmitted;
        this.eventId = eventId;
        this.waitingApproval = waitingApproval;
    }

    get isOnline() {
        return this.presence?.state === 'online';
    }

    get isFreeToTalk() {
        return this.status === 'free';
    }

    get isSpeaker() {
        return this.role === 'speaker';
    }

    get isHost() {
        return this.role === 'host';
    }

    get isModerator() {
        return this.role === 'moderator';
    }

    static mapFromServer(data: any) {
        return new AttendeeModel(
            data.id,
            data.user,
            data.presence,
            data.attended,
            data.status || 'free',
            data.notifyEntrance,
            data.role || 'attendee',
            data.firstTimer,
            data.feedbackSubmitted,
            data.eventId,
            data.waitingApproval
        );
    }
}

export type AttendeeStatus = 'free' | 'busy';

export type AttendeeRole = 'speaker' | 'host' | 'moderator' | 'attendee';

export default AttendeeModel;
