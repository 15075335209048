import { Alert, AutoComplete, Button, Form, Input, message, Radio, Switch, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import UserAvatar from '../../../../common/components/avatar/UserAvatar';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';
import UserModel from '../../../../common/models/UserModel';
import OnboardingStore from '../../../../store/OnboardingStore';
import InputLabel from '../inputLabel/InputLabel';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import ProfilePhoto from '../profilePhoto/ProfilePhoto';
import StepWrapper from '../stepWrapper/StepWrapper';
import styles from './BasicDetails.module.css';
import Algolia from '../../../../services/Algolia';
import CompanyBox from '../companyBox/CompanyBox';
import { exists } from 'fs';
import AnalyticsReporter from '../../../../services/analytics/AnalyticsReporter';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { PrivacyPolicy, TermsOfUse } from '../LegalLinks';
import SessionStore from '../../../../store/SessionStore';

const { Option } = AutoComplete;

interface Props {
    onNext: () => void,
    onBack: () => void,
    currentUser?: UserModel,
    onboardingStore?: OnboardingStore,
    sessionStore?: SessionStore,
    analyticsReporter?: AnalyticsReporter,
    algolia?: Algolia,
}


function BasicDetails({ sessionStore, onboardingStore, onNext, onBack, algolia, analyticsReporter, currentUser }: Props) {
    const [hits, setHits] = useState(Array<any>());
    const [companyInputValue, setCompanyInputValue] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [companyMissingError, setCompanyMissingError] = useState(false);
    const [fails, setFails] = useState(0);
    const { addCompanyDrawer } = onboardingStore!;
    const { authUser } = sessionStore!;
    const [form] = Form.useForm();

    const { initialized,
        onBasicDetailsSubmit,
        setSelectedCompany,
        setAddCompanyDrawerIntialData,
        firstNameInputValue,
        lastNameInputValue,
        roleInputValue,
        selectedCompany,
        photoUrlInputValue } = onboardingStore!;

    useEffect(() => {
        analyticsReporter?.trackPage('Onboarding Basic Details');
    }, [])


    if (!initialized) {
        return <div></div>
    }

    const onCompanySearch = async (searchValue: string) => {
        if (searchValue.length < 3) {
            return;
        }

        const results: any = await algolia?.search(searchValue);
        results.push({ type: 'add' })
        setHits(results || []);
    }

    const onCompanySelected = (value: string, option: any) => {
        if (!option.extra) {
            form.setFieldsValue({ companySelect: companyInputValue });
            return;
        }

        const company = option.extra;
        setSelectedCompany(company);
        setCompanyMissingError(false);
    }

    const onCompanyValueChange = (value: string) => {
        setCompanyInputValue(value);
    }

    const onCompanyUnlink = () => {
        setSelectedCompany(null);
    }

    const addNewCompany = () => {
        setAddCompanyDrawerIntialData(companyInputValue);
        addCompanyDrawer(true);
    }

    const onCompanyInputBlur = () => {
        if(!selectedCompany) {
            setCompanyMissingError(true);
        }
    }

    const onSubmit = async (values: any) => {
        // if (!selectedCompany) {
        //     message.warning(`Please link your company to your profile. In case it doesn't show up, simply add it`);
        //     setCompanyMissingError(true);
        //     return;
        // }

        if (!photoUrlInputValue && !authUser?.isGuest) {
            message.info(`Please add a nice picture of yourself`);
            return;
        }

        setIsBusy(true);
        setTimeout(async () => {
            await onBasicDetailsSubmit(values.firstName, values.lastName, values.role, companyInputValue, values.gender);
            setIsBusy(false);
            onNext();
        }, 1500)

    }

    return <StepWrapper title={'The Basics'}
        subTitle={'Help us and other members to get to know you better.'}>
        <div className={styles.content}>
            <Form
                style={{ width: '100%' }}
                form={form}
                name="basicDetails"
                layout="vertical"
                initialValues={{
                    firstName: firstNameInputValue,
                    lastName: lastNameInputValue,
                    role: roleInputValue,
                }}
                onFinish={onSubmit}
                onFinishFailed={() => {
                    message.warn(fails > 0 ? 'Some fields are still missing - please scroll through and see if you missed anything.' : 'Some fields are missing!');
                    setFails(prev => prev + 1);
                }}
            >
                <Form.Item
                    name='profilePhoto'>
                    <Form.Item>
                        <div className={styles.userProfile}>
                            <ProfilePhoto initialImageUrl={photoUrlInputValue} />
                            <div className={styles.profileLabels}>
                                <div className={styles.profileHeaderText}> Profile Photo </div>
                                <div className={styles.profileSubHeaderText}>Choose a nice picture of yourself.</div>
                            </div>
                        </div>
                    </Form.Item>

                </Form.Item>

                <div className={styles.doubleInputGrid}>
                    <Form.Item
                        label='First Name'
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter your first name.' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                    <Form.Item
                        label='Last Name'
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter your last name.' }]}
                    >
                        <Input size='large' />
                    </Form.Item>
                </div>

                <Form.Item label='Company'
                    name='companySelect'>
                    <CompanyBox style={selectedCompany ? {} : { display: 'none' }} name={selectedCompany?.name} logoUrl={selectedCompany?.logo?.url} onUnlink={onCompanyUnlink} />
                    <AutoComplete size={'large'}
                        style={selectedCompany ? { display: 'none' } : {}}
                        onSearch={onCompanySearch}
                        onSelect={onCompanySelected}
                        onChange={onCompanyValueChange}
                        onBlur={onCompanyInputBlur}
                    >
                        {hits.map((hit: any) => {
                            return hit.type === 'add' ?
                                <Option key='add' value=''>
                                    <div className={styles.addNewButtonLink} onClick={addNewCompany}>+ Add New Company</div>
                                </Option>
                                :
                                <Option key={hit._id} value={hit.name} extra={hit} >
                                    <div className={styles.companySearchHit}>
                                        <img src={hit.logo?.url} className={styles.companySearchLogo} />
                                        <div className={styles.companySearchName}>{hit.name}</div>
                                    </div>
                                </Option>
                        })}
                    </AutoComplete>
                    {companyMissingError &&
                    <Alert style={{ marginTop: 12}} message="Linked companies get better representation. Consider linking an existing company or adding a new one if yours missing." type="warning" showIcon />
                    }
                </Form.Item>
                <Form.Item
                    label='Role'
                    name="role"
                    rules={[{ required: true, message: 'Please enter your role in the company.' }]}
                >
                    <Input size='large' placeholder={'i.e - CEO, VP Marketing etc.'} />
                </Form.Item>
                <Form.Item
                    label={`I'm a...`}
                    name="gender"
                    rules={[{ required: true, message: 'Please select one.' }]}
                >
                    <Radio.Group size='large'>
                        <Radio.Button value={'male'}>Man</Radio.Button>
                        <Radio.Button value={'female'}>Woman</Radio.Button>
                        <Radio.Button value={'non-binary'}>Non-binary</Radio.Button>
                    </Radio.Group>

                </Form.Item>
                {currentUser?.location?.isEU &&
                    <Form.Item
                        name='consent'
                        valuePropName='checked'
                        rules={[{ required: true, message: 'Please accept our Terms of Use and Privacy Polict.' }]}
                    >
                        <div className={styles.consent}>
                            <Checkbox style={{ marginRight: 8 }} />
                            <span>I accept your <TermsOfUse /> and <PrivacyPolicy />.</span>
                        </div>

                    </Form.Item>
                }
                <NavigationButtons buttonTitle={'Next'}
                    isBusy={isBusy}
                    showBack={false}
                    onBack={onBack} />
            </Form>
        </div>

    </StepWrapper>

}



export default inject('sessionStore','onboardingStore', 'algolia', 'analyticsReporter')(observer(BasicDetails));

