import _ from "lodash";
import moment from "moment";
import UserModel, { UserPublicData } from "./UserModel";

class EventModel {
    id?: string;
    shortId?: string;
    title?: string;
    description?: string;
    type?: string;
    startDate?: number;
    endDate?: number;
    timezone?: string;
    eventPlatform?: string;
    isActive?: boolean;
    isEnded?: boolean;
    isQuestionsEnabled?: boolean;
    isPrivateTalksEnabled?: boolean;
    isRoundTablesEnabled?: boolean;
    isStageEnabled?: boolean;
    speakers?: Speaker[];
    location?: string;
    externalLink?: string;
    questions?: Question[];
    state?: EventState;
    stateTransition?: EventStateTransition;
    webinarId?: string;
    spatialLink?: string;
    theme?: EventTheme;
    themeColor?: string;
    themeTitle?: string;
    themeBackground?: string;
    attendeesSnippet?: string[];
    attendeesCount?: number;
    targets?: TargetAudience[];
    timer?: EventTimerModel;
    seatLimit?: number;
    attendeesIds?: string[];
    isHidden?: boolean;
    stats?: EventStats;
    autoStart?: boolean;
    recordUrl?: string; 
    approvalNeeded?: string[]
    reminder?: EventReminder;

    constructor(id?: string,
        shortId?: string,
        title?: string,
        description?: string,
        type?: string,
        startDate?: number,
        endDate?: number,
        timezone?: string,
        isActive?: boolean,
        isQuestionsEnabled?: boolean,
        eventPlatform?: string,
        speakers?: Speaker[],
        location?: string,
        externalLink?: string,
        theme?: EventTheme,
        questions?: Question[],
        state?: EventState,
        stateTransition?: EventStateTransition,
        isEnded?: boolean,
        webinarId?: string,
        spatialLink?: string,
        isPrivateTalksEnabled?: boolean,
        isRoundTablesEnabled?: boolean,
        themeColor?: string,
        themeTitle?: string,
        attendeesSnippet?: string[],
        attendeesCount?: number,
        themeBackground?: string,
        targets?: TargetAudience[],
        isStageEnabled?: boolean,
        timer?: EventTimerModel,
        seatLimit?: number,
        attendeesIds?: string[],
        isHidden?: boolean,
        stats?: EventStats,
        autoStart?: boolean,
        recordUrl?: string,
        approvalNeeded?: string[],
        reminder?: EventReminder) {
        this.id = id;
        this.shortId = shortId;
        this.title = title;
        this.description = description;
        this.type = type;
        this.startDate = startDate;
        this.endDate = endDate;
        this.timezone = timezone;
        this.isActive = isActive;
        this.isQuestionsEnabled = isQuestionsEnabled;
        this.eventPlatform = eventPlatform;
        this.speakers = speakers;
        this.location = location;
        this.externalLink = externalLink;
        this.theme = theme;
        this.questions = questions;
        this.state = state;
        this.stateTransition = stateTransition;
        this.isEnded = isEnded;
        this.webinarId = webinarId;
        this.spatialLink = spatialLink;
        this.isPrivateTalksEnabled = isPrivateTalksEnabled;
        this.isRoundTablesEnabled = isRoundTablesEnabled;
        this.isStageEnabled = isStageEnabled;
        this.themeColor = themeColor;
        this.themeTitle = themeTitle;
        this.themeBackground = themeBackground;
        this.attendeesSnippet = attendeesSnippet;
        this.attendeesCount = attendeesCount;
        this.targets = targets;
        this.timer = timer;
        this.seatLimit = seatLimit;
        this.attendeesIds = attendeesIds;
        this.isHidden = isHidden;
        this.stats = stats;
        this.autoStart = autoStart;
        this.recordUrl = recordUrl;
        this.approvalNeeded = approvalNeeded;
        this.reminder = reminder;
    }

    localizedDate(unixTime?: number) {
        if (!unixTime || !this.timezone) {
            return;
        }

        return moment.unix(unixTime).tz(moment.tz.guess());
    }

    get longDateString() {
        const localizedDate = this.localizedDate(this.startDate);

        if (moment().isSame(moment(localizedDate).add(-1, 'day'), 'day')) {
            return `Tomorrow, ${localizedDate?.format('h:mmA z')}`;
        }
        else if (moment().isSame(localizedDate, 'day')) {
            return `Today, ${localizedDate?.format('h:mmA z')}`;
        } else {
            return localizedDate?.format('dddd, MMM D, h:mmA z');
        }

    }

    get fullDateString() {
        const localizedEndDate = this.localizedDate(this.endDate);
        return `${this.longDateString} to ${localizedEndDate?.format('h:mm A')}`
    }

    get locationString() {
        return this.type === 'online' ? 'Online' : this.location;
    }

    get isInHouseEvent() {
        return this.eventPlatform === 'in-house';
    }

    get isStageActive() {
        return this.state === 'stage';
    }

    get isRoundTablesActive() {
        return this.state === 'tables';
    }

    get hasSeatLimit() {
        return this.seatLimit && this.seatLimit > 0;
    }

    toPublicData() {
        return {
            id: this.id,
            shortId: this.shortId,
            title: this.title,
            startDate: this.startDate,
            endDate: this.endDate,
            timezone: this.timezone,
            location: this.location,
        }
    }

    static mapFromServer(data: any) {
        return new EventModel(
            data.id,
            data.shortId,
            data.title,
            data.description,
            data.type,
            data.startDate,
            data.endDate,
            data.timezone,
            data.isActive,
            data.isQuestionsEnabled,
            data.eventPlatform,
            data.speakers,
            data.location,
            data.externalLink,
            data.theme,
            data.questions,
            data.state || 'lounge',
            data.stateTransition,
            data.isEnded,
            data.webinarId,
            data.spatialLink,
            data.isPrivateTalksEnabled,
            data.isRoundTablesEnabled,
            data.themeColor,
            data.themeTitle,
            data.attendeesSnippet,
            data.attendeesCount,
            data.themeBackground,
            data.targets,
            data.isStageEnabled,
            data.timer,
            data.seatLimit,
            data.attendeesIds,
            data.isHidden,
            data.stats,
            data.autoStart,
            data.recordUrl,
            data.approvalNeeded,
            data.reminder);
    }
}

export type EventPublicData = {
    id?: string;
    shortId?: string;
    title?: string;
    startDate?: number;
    endDate?: number;
    timezone?: string;
    location?: string;
}

export type Speaker = {
    title: string,
    name: string,
    companyName: string,
    companyLogoUrl: string,
    photoUrl: string,
    role: 'host' | 'speaker' | 'guest' | 'panelist' | 'expert',
}

export type EventState = 'lounge' | 'stage' | 'tables';
export type EventTheme = 'forum' | 'panelist' | 'other';

export type EventStateTransition = {
    to: EventState
}

export type EventTimerModel = {
    label: string,
    setTo: number,
}

export type Question = {
    id: string,
    text: string,
    createdAt: number,
    approved: boolean,
    askingUser?: UserPublicData
}

export type EventFeedback = {
    id: string,
    by: UserPublicData,
    score: number,
    notes: string,
}

export type EventStats = {
    totalMembersAtTheTime: number,
    totalRegistered: number
    totalAttended: number
    membersRegistered: number
    membersAttended: number
    guestsRegistered: number
    guestsAttended: number
    feedbacksCollected: number
    feedbackScore: number
}

export type TargetAudience = SpecificMembersTargeting | CohortTargeting;

export type SpecificMembersTargeting = {
    type: 'members' | 'sectors';
    members: UserPublicData[];
}

export type CohortTargeting = {
    type: 'cohorts';
    cohorts: string[];
}


export type EventReminder = {
    timeBefore: number;
    wasSent: boolean;
    enabled: boolean;
}

export default EventModel;