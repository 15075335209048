import { Button, Modal, Tooltip } from "antd";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import ViewStateStore from "../../../store/ViewStateStore";
import { CheckOutlined, CheckCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import styles from './PaywallModal.module.css';
import CheckoutPage from "../../../pages/checkout/CheckoutPage";
import { useEffect } from "react";
import SessionStore from "../../../store/SessionStore";
import DiscountBadge from "../discountBadge/DiscountBadge";



interface Props {
    viewStateStore?: ViewStateStore;
    sessionStore?: SessionStore;
}

function PaywallModal({ viewStateStore, sessionStore }: Props) {
    const [mode, setMode] = useState<'plans' | 'checkout'>('plans')
    const [selectedPlan, setSelectedPlan] = useState('');
    const { paywallModalVisible } = viewStateStore!;


    const onDismiss = () => {
        setTimeout(() => setMode('plans'), 2000);
        viewStateStore?.setPaymentWallVisible(false);
    }

    const onChosenPlanClicked = (plan: string) => {
        setSelectedPlan(plan);
        setMode('checkout');
        sessionStore?.addActivityItem(`Checkout Page Shown (selected plan: ${plan})`, 'payment', 'money');
    }

    const onChangePlan = () => {
        setSelectedPlan('');
        setMode('plans');
    }

    const commonPerks = [
        {
            content: <span>Unlimited access to our <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}><Tooltip placement='right' color={viewStateStore?.primaryColor} title='Intimate discussion and networking with industry leaders from companies like Booking, Airbnb, TripAdvisor and more'>Captains of Industry</Tooltip></span> member meetings</span>
        },
        {
            content: <span>Priority access to our <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}><Tooltip placement='right' color={viewStateStore?.primaryColor} title='Intimate, off-record curated roundtable discussions based on your business interests, moderated by industry experts and Phocuswright analysts. Space is limited in order to facilitate meaningful conversations and networking opportunities.'>Think Tank</Tooltip></span> series member meetings</span>,
        },
        {
            content: <span>Automated Introduction Engine incl. personal networking CRM</span>,
        },
        {
            content: <span>Access to our internal members and companies directory</span>,
        },
        {
            content: <span>Free access to tailor-made Phocuswright content and resources</span>,
        },

    ];

    const yearlyPerks = [
        {
            icon: <PlusCircleFilled className={styles.perkCheckmark} />,
            content: <span><b>Everything included in the 6-Month Membership</b></span>,
        },
        {
            content: <span>Invitation to our exclusive members-only face-to-face <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}><Tooltip className={styles.perkTooltip} placement='right' color={viewStateStore?.primaryColor} title='In-person invite-only satellite events as part of the main Phocuswright conferences'>gatherings</Tooltip></span></span>,
        },
        {
            content: <span>Exclusive <span className={styles.boldedPerk}>$500 credit</span> for the global 
               <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}> <Tooltip className={styles.perkTooltip} placement='right' color={viewStateStore?.primaryColor} title={<span>See also: <a style={{ color: 'white', textDecoration: 'underline'}} href='https://www.phocuswrightconference.com' target='_blank'>phocuswrightconference.com</a></span>}>Phocuswright Conference 2022</Tooltip></span> or <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}> <Tooltip className={styles.perkTooltip} placement='right' color={viewStateStore?.primaryColor} title={<span>See also: <a style={{ color: 'white', textDecoration: 'underline'}} href='https://www.phocuswrighteurope.com/' target='_blank'>https://www.phocuswrighteurope.com/</a></span>}>Phocuswright Europe 2022</Tooltip></span>
            </span>
        },
        {
            content: <span><span className={styles.boldedPerk}>$500 off</span> a Phocuswright Research purchase</span>
        },
        {
            content: <span>Two (2) mentee memberships to <span className={`${styles.underlinedLink} ${styles.boldedPerk}`}><Tooltip className={styles.perkTooltip} placement='right' color={viewStateStore?.primaryColor} title='The first-ever industry-wide mentorship program'>Phocuswright Connects</Tooltip></span> ($400 value)</span>
        },

    ];


    return (<Modal
        visible={paywallModalVisible}
        centered
        footer={null}
        closable
        width='auto'
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
        onCancel={onDismiss}
        className={styles.modal}>
        {mode === 'plans' ?
            <div className={styles.content}>
                <DiscountBadge style={{ fontSize: 14 }} />
                <div className={styles.header}>Become a Premium Member</div>
                <div className={styles.description}>Get unlimited access to our member meetings, perks and more.</div>
                <div className={styles.plans}>
                    <PricingPlan
                        id='6-Month-Membership'
                        title='6-Month Membership'
                        oldPrice={199}
                        onChosenPlanClicked={onChosenPlanClicked}
                        price={99}
                        billingCycle='billed every 6 months'
                        description={'A 6-month membership of unlimited access to our meetings and benefits.'}
                        perks={commonPerks}
                    />
                    <PricingPlan title='Yearly Membership'
                        id='Yearly-Membership'
                        oldPrice={169}
                        onChosenPlanClicked={onChosenPlanClicked}
                        popular
                        price={85}
                        billingCycle='billed yearly'
                        perks={yearlyPerks}
                        description={'Get the most of our Premium Membership with a discounted price and extra perks.'} />
                </div>

            </div>
            :
            <div className={styles.content}>
                <CheckoutPage
                    external={false}
                    selectedPlan={selectedPlan}
                    onChangePlan={onChangePlan} />
            </div>
        }
    </Modal>);
}

interface PricingPlanProps {
    id: string,
    title: string,
    popular?: boolean,
    description: string,
    price: number,
    oldPrice: number,
    currency?: string,
    billingCycle: string,
    perks?: { icon?: React.ReactNode, content: React.ReactNode }[],
    period?: 'month' | 'year'
    onChosenPlanClicked: (plan: string) => void;
}

const PricingPlan = ({ id, title, popular = false, description, price, oldPrice, currency = '$', period = 'month', perks, onChosenPlanClicked, billingCycle }: PricingPlanProps) => {

    return <div className={styles.pricingPlan}>
        {popular &&
            <div className={styles.badge}>
                Most Popular
            </div>
        }
        <div className={styles.pricingHeader}>
            {title}
        </div>
        <div className={styles.pricing}>
            <span className={styles.oldPrice}>{currency}{oldPrice}</span><span className={styles.price}>{currency}{price}</span> <span className={styles.planTime}>/{period}</span><span className={styles.billingCycle}>({billingCycle})</span>
        </div>
        <div className={styles.planDescription}>
            {description}
        </div>

        <div className={styles.perks}>
            {perks?.map(perk =>
                <div className={styles.perkContainer}>
                    {perk.icon ? perk.icon : <CheckCircleOutlined className={styles.perkCheckmark} />} <span className={styles.perk}>{perk.content}</span>
                </div>
            )}

        </div>

        <Button onClick={() => onChosenPlanClicked(id)} size={'large'} type='primary' className={popular ? styles.accessButton : styles.notPopularButton}>
            Get Access
        </Button>
    </div>
}

export default inject('viewStateStore', 'sessionStore')(observer(PaywallModal));