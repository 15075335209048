import { Avatar, Button, message, Modal, notification, Popconfirm, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../common/components/card/Card';
import EventModel from '../../../common/models/EventModel';
import FirebaseClient from '../../../services/FirebaseClient';
import EventsStore from '../../../store/EventsStore';
import SessionStore from '../../../store/SessionStore';
import NewEventDrawer from './components/addEventDrawer/AddEventDrawer';
import styles from './EventInfoPage.module.css';
import { ClockCircleOutlined, EnvironmentOutlined, LineChartOutlined } from '@ant-design/icons';
import SpeakerItem from './components/speakerItem/SpeakerItem';
import _ from 'lodash';
import ReactMarkdown from "react-markdown";
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import UserModel from '../../../common/models/UserModel';
import UserBox from '../members/components/userBox/UserBox';
import MembersStore from '../../../store/MembersStore';
import EventPromo from './components/eventPromo/EventPromo';
import { InfoCircleOutlined } from '@ant-design/icons';
import LiveBadge from './components/liveBadge/LiveBadge';
import EventTheme from './components/eventTheme/EventTheme';
import AttentedModal from './components/eventAttentedModal/AttentedModal';
import AddToCalendar from './components/addToCalendar/AddToCalendar';
import AddQuestionModal from './components/addQuestionModal/AddQuestionModal';
import moment from 'moment';
import StringUtils from '../../../common/utils/StringUtils';
import EventQuestions from './components/eventQuestions/EventQuestions';
import AttendeeModel from '../../../common/models/AttendeeModel';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import { MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER } from './components/eventCards/components/peopleGoingSnippet/PeopleGoingSnippet';
import EventCapabilities from './components/eventCapabilities/EventCapabilities';
import ManageAttendeesModal from './components/manageAttendeesModal/ManageAttendeesModal';
import EventMetrics from './components/eventMetrics/EventMetrics';
import ViewStateStore from '../../../store/ViewStateStore';
import GuestInviteModal from './components/guestInviteModal/GuestInviteModal';
import SettingsStore from '../../../store/SettingsStore';
import { RegistrationStatus } from '../../guestConfirmation/GuestConfirmationPage';

interface Props {
    sessionStore: SessionStore;
    eventsStore: EventsStore;
    membersStore: MembersStore;
    settingsStore: SettingsStore;
    firebase: FirebaseClient;
    analyticsReporter: AnalyticsReporter;
    viewStateStore: ViewStateStore;
    location: any;
    history: any;
    match: any;
}

function EventInfoPage({ sessionStore, eventsStore, membersStore, firebase, match, history, analyticsReporter, viewStateStore, settingsStore }: Props) {
    const [adminMode, setAdminMode] = useState(true);
    const [editEventDrawerOpen, setEditEventDrawerOpen] = useState(false);
    const [isBusy, setIsBusy] = useState(true);
    const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus>('none')
    const [attendModalVisible, setAttendModalVisible] = useState(false);
    const [metricsModalVisible, showMetricsModal] = useState(false);
    const [guestInviteModalVisible, showGuestInviteModal] = useState(false);
    const [questionModalVisible, setQuestionModalCancel] = useState(false);
    const [manageAttendeesModalVisible, setManageAttendeesModalVisible] = useState(false);
    const [event, setEvent] = useState<EventModel>();
    const [attendees, setAttendees] = useState(Array<AttendeeModel>());
    const { authUser } = sessionStore;
    const { viewMember } = membersStore;
    const { terminology } = settingsStore;

    const approvalNeeded = eventsStore?.checkIfApprovalNeeded(event);
    const approvedAttendees = attendees.filter(a => !a.waitingApproval);
    const waitingApprovalAttendees = attendees.filter(a => a.waitingApproval);

    useEffect(() => {
        analyticsReporter.trackPage('Event Page');
    }, [])

    useEffect(() => {
        const { eventId } = match.params;
        const unsubscribe = firebase.events().where('shortId', '==', eventId).onSnapshot(snap => {
            if (snap.empty) {
                console.error('No event found for ' + eventId);
                return;
            }

            const eventDoc = snap.docs[0];
            setEvent(EventModel.mapFromServer({ id: eventDoc.id, ...eventDoc.data() }));
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!event || !event.id) {
            return;
        }
        const unsubscribe = firebase.event(event.id).collection('attendees').onSnapshot(snap => {
            const attendees: AttendeeModel[] = [];
            snap.docs.map(doc => {
                attendees.push(AttendeeModel.mapFromServer({ id: doc.id, ...doc.data() }));
            })

            const attendee = attendees.find(a => a.id === authUser?.id);
            if (attendee) {
                setRegistrationStatus(attendee?.waitingApproval ? 'pending' : 'confirmed');
            } else {
                setRegistrationStatus('none');
            }

            setAttendees(attendees);
            setIsBusy(false);
        });

        return () => unsubscribe();

    }, [event])

    const attend = async () => {
        if (!event?.id) {
            return;
        }

        setIsBusy(true);
        const result = await eventsStore.attendEvent(event);
        setIsBusy(false);
        if (result?.registered) {
            setAttendModalVisible(true);
        } else {
            if (result?.reason === 'no_credits') {
                viewStateStore.setPaymentWallVisible(true, 'Event Registration');
            } else {
                message.error('We had problem registering you to this event. Please contact us for help.')
            }

        }

    }

    const unattend = async () => {
        setIsBusy(true);
        await eventsStore.unattendEvent(event);
        setTimeout(() => {
            setIsBusy(false);
        }, 1000);
    }

    const joinEvent = () => {
        if (registrationStatus === 'none') {
            notification.open({
                message: 'Please register first',
                description:
                    'You must register to the event before joining.',
                icon: <InfoCircleOutlined className={styles.notificationIcon} />,
            });
            return;
        }

        if (registrationStatus === 'pending') {
            notification.open({
                message: 'Your registration is still pending',
                description:
                    'Our team needs to approve your registration first',
                icon: <InfoCircleOutlined className={styles.notificationIcon} />,
            });
            return;
        }


        window.open(`/live/${event!.shortId}`);

    }

    const onMemberClicked = (userModel: UserModel) => {
        viewMember(userModel);
    }

    const openEditEventDrawer = () => {
        setEditEventDrawerOpen(true);
    }

    const onAttendedModalCancel = () => {
        setAttendModalVisible(false);
    }

    const onSubmitQeustionClicked = () => {
        setAttendModalVisible(false);
        setQuestionModalCancel(true);
    }

    const onQuestionModalCancel = () => {
        setQuestionModalCancel(false);
    }

    const canEnterBackstage = () => {
        const attendeeModel = attendees.find(a => a.id === authUser?.id);
        if (attendeeModel) {
            return attendeeModel.isSpeaker || attendeeModel.isHost;
        } else {
            return false;
        }
    }

    const onQuestionSubmitted = async (question: string) => {
        await eventsStore.addQuestion(event!.id!, {
            id: StringUtils.uniqueId(10),
            text: question,
            createdAt: moment().unix(),
            askingUser: authUser?.toPublicData(),
            approved: false,
        })

        setQuestionModalCancel(false);
        notification.success({
            placement: 'bottomRight',
            message: 'Good question!',
            description: `We'll do our best to address it during the session.`
        })
    }

    const onDeleteEvent = async () => {
        await eventsStore.deleteEvent(event!.id!);
        message.success('Event has been canceled!');
        setTimeout(() => {
            history.push('/calendar');
        }, 2000);
    }

    const onStartEvent = async () => {
        setIsBusy(true);
        try {
            await eventsStore.startEvent(event!.id!);
            message.success('Event has started!');
        } catch (error) {
            console.log(`Starting event failed: ${error}`);
            message.error('We had trouble starting this event...');
        } finally {
            setIsBusy(false);
        }
    }

    const onEndEvent = async () => {
        await eventsStore.endEvent(event!.id!);

        Modal.confirm({
            width: 600,
            content: (<div className={styles.notesContainer}>
                Would you like to collect feedback from attendees?
            </div>),
            title: 'Feedback Collection',
            okText: 'Yes',
            async onOk() {
                eventsStore.collectFeedback(event?.id!);
                message.success('Feedback collection emails were sent!');
            },
            onCancel() {
                Modal.destroyAll();
            },
        })
    }

    const onEditEventDrawerClosed = () => {
        setEditEventDrawerOpen(false);
    }

    const showEventEndedNotifcaiton = () => {
        message.info('Too late, event was ended.');
    }

    const approveAttendee = async (attendee: AttendeeModel) => {
        setIsBusy(true);
        await eventsStore?.approveAttendee(event?.id!, attendee.user!);
        setIsBusy(false);
        message.success('Application has been approved.')
    }

    const denyAttendee = async (attendee: AttendeeModel) => {
        setIsBusy(true);
        await eventsStore?.denyAttendee(event?.id!, attendee.user!);
        setIsBusy(false);
        message.success('Application has been denied')
    }

    const getRegistrationSnippet = () => {
        if (registrationStatus === 'confirmed') {
            return `You're going to this ${terminology?.eventTerm}!`
        }
        if (registrationStatus === 'pending') {
            return `⏳ Waiting Approval`
        }

        return `Want to join this ${terminology?.eventTerm}?`;
    }

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.detailsContainer}>
                    <Card className={styles.mainDetails}>
                        <div className={styles.themeStrip} style={{ backgroundColor: event?.themeColor }} />
                        <div className={styles.eventTitle}>
                            {event?.title}
                        </div>
                        {event?.themeTitle && <EventTheme theme={event?.themeTitle} themeColor={event?.themeColor} className={styles.themesContainer} />}
                        <div className={styles.divider} />
                        <div className={styles.date}>
                            <ClockCircleOutlined className={styles.icon} />
                            <div className={styles.dateContainer}>
                                <span>{event?.fullDateString} ({moment.tz.guess()})</span>
                            </div>
                        </div>

                        <div className={styles.location}>
                            <EnvironmentOutlined className={styles.icon} />
                            <div>{event?.locationString}</div>
                        </div>
                        <ReactMarkdown className={styles.eventDescription} source={event?.description || ''} />
                        {event?.speakers && event?.speakers.length > 0 &&
                            <div className={styles.miniHeader}>
                                {`Featuring`}
                            </div>
                        }
                        <div className={styles.speakersGrid}>
                            {_.sortBy(event?.speakers, s => s.role).map((speaker, index) => {
                                return <SpeakerItem colored={true} mode='large' key={index} speaker={speaker} />
                            })}
                        </div>
                        <EventCapabilities className={styles.eventCapabilities} eventModel={event} />
                    </Card>
                    <div className={styles.extraDetails}>
                        {!event?.isEnded &&
                            <Card className={styles.regsiterContainer}>
                                <div className={styles.registerSnippet}>
                                    {getRegistrationSnippet()}
                                </div>
                                {registrationStatus === 'pending' || registrationStatus === 'confirmed' ?
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={event?.isEnded ? showEventEndedNotifcaiton : unattend}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button loading={isBusy} size='large' className={styles.registerButton} >
                                            Cancel Registration
                                        </Button>
                                    </Popconfirm>
                                    :
                                    <Button loading={isBusy} type='primary' size='large' className={styles.registerButton} onClick={event?.isEnded ? () => showEventEndedNotifcaiton : attend}>
                                        {approvalNeeded ? 'Apply to join' : 'Register'}
                                    </Button>
                                }
                                {attendees.length < MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER && registrationStatus === 'none' &&
                                    <div className={styles.beFirstToJoinText}>
                                        Seats are limited - save your spot!
                                    </div>
                                }
                                {attendees.length >= MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER &&
                                    <div className={styles.attendeesSnippet}>
                                        <Avatar.Group
                                            maxCount={4}
                                            maxStyle={{ height: 54, width: 54, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                            {attendees.filter(a => !a.waitingApproval).map(attendee => {
                                                return <Tooltip key={attendee?.id} title={`${attendee.user?.company?.name}`} placement="top">
                                                    <Avatar size={54} src={attendee?.user?.photoUrl} />
                                                </Tooltip>
                                            })}
                                        </Avatar.Group>
                                        <div className={styles.attendessSnipetText}>
                                            {attendees.filter(a => !a.waitingApproval).length} members are going to this {terminology?.eventTerm}.
                                        </div>
                                    </div>
                                }
                            </Card>
                        }
                        {event?.startDate &&
                            <EventPromo
                                event={event}
                                joinEvent={joinEvent}
                                isEnded={event.isEnded || false}
                                isActive={event.isActive || false}
                                startDate={event.startDate}
                                backstageAccess={canEnterBackstage()}
                                endDate={event.endDate} />
                        }

                        {adminMode && authUser?.isStaff &&
                            <Card className={styles.adminSection}>
                                {event?.isActive && !event?.isEnded &&
                                    <Popconfirm
                                        title="Are you sure you want to end this event?"
                                        onConfirm={onEndEvent}
                                        okText="End Event"
                                        cancelText="Cancel">
                                        <Button loading={isBusy} size='large' className={styles.endEventButton}>
                                            End Event
                                        </Button>
                                    </Popconfirm>
                                }
                                {!event?.isEnded && !event?.isActive &&
                                    <Popconfirm
                                        title="Are you sure you want to start? We'll send a notifcaiton to all attendees."
                                        onConfirm={onStartEvent}
                                        okText="Start Event"
                                        cancelText="Cancel">
                                        <Button loading={isBusy} size='large' type='primary' className={styles.startEventButton}>
                                            Start Event
                                        </Button>
                                    </Popconfirm>
                                }

                                <Tooltip title={'Enter the event venue without starting the event.'}>
                                    <Button onClick={joinEvent} loading={isBusy} size='large' className={styles.startEventButton}>
                                        Enter Event Venue
                                    </Button>
                                </Tooltip>

                                <Button onClick={openEditEventDrawer} size='large' className={styles.startEventButton}>
                                    Edit Details
                                </Button>
                                <Button onClick={() => showGuestInviteModal(true)} size='large' className={styles.startEventButton}>
                                    Generate Guest Invite
                                </Button>
                                <Button onClick={() => showMetricsModal(true)} size='large' className={styles.registerButton}>
                                    Metrics
                                </Button>
                                <Popconfirm
                                    title="Are you sure delete this event? We'll notify all the attendes that this event was canceled."
                                    onConfirm={onDeleteEvent}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button size='large' className={styles.deleteEventButton}>
                                        Cancel this event
                                    </Button>
                                </Popconfirm>
                                <div className={styles.adminSnippet}>Only staff can see these options.</div>
                            </Card>
                        }
                        {authUser?.isStaff &&
                            <div className={styles.adminOptionsButton} onClick={() => setAdminMode(mode => !mode)}>
                                {adminMode ? 'Hide' : 'Manage Event'}
                            </div>
                        }
                    </div>

                </div>

                {authUser?.isStaff && event?.questions &&
                    <EventQuestions questions={event.questions} />
                }


                {waitingApprovalAttendees.length > 0 && authUser?.isStaff &&
                    <div className={styles.attendeesContainer}>
                        <SectionHeader size='medium' title={`Applications`}
                            subTitle={`Approve or deny applications for this ${terminology?.eventTerm}.`} />

                        <div className={styles.membersContainer}>
                            {waitingApprovalAttendees.map(attendee =>
                                <div className={styles.applicationContainer}>
                                    <UserBox
                                        onClick={onMemberClicked}
                                        key={attendee.id}
                                        userModel={attendee.user} />
                                    <div className={styles.applicationButtons}>
                                        <Button loading={isBusy} onClick={() => approveAttendee(attendee)} className={styles.approveButton}>Approve</Button>
                                        <Popconfirm title={'Are you sure?'} onConfirm={() => denyAttendee(attendee)}>
                                            <Button loading={isBusy} className={styles.denyButton}>Deny</Button>
                                        </Popconfirm>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                }

                {(approvedAttendees.length >= MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER || authUser?.isStaff) &&
                    <div className={styles.attendeesContainer}>
                        <SectionHeader size='medium' title={`Attendees`}
                            subTitle={`See who you're going to meet in this ${terminology?.eventTerm}.`}
                            linkTitle={authUser?.isManager ? 'Manage' : ''}
                            onLinkClicked={() => setManageAttendeesModalVisible(true)} />

                        <div className={styles.membersContainer}>
                            {approvedAttendees.map(attendee => <UserBox
                                onClick={onMemberClicked}
                                key={attendee.id}
                                userModel={attendee.user} />)}
                        </div>
                    </div>
                }
                <NewEventDrawer
                    event={event}
                    isOpen={editEventDrawerOpen}
                    onClose={onEditEventDrawerClosed} />

                <AttentedModal registrationStatus={registrationStatus} onCancel={onAttendedModalCancel} visible={attendModalVisible} event={event} onSubmitQestionClicked={onSubmitQeustionClicked} />
                <AddQuestionModal onSubmit={onQuestionSubmitted} onCancel={onQuestionModalCancel} visible={questionModalVisible} />
                <ManageAttendeesModal
                    event={event}
                    attendees={approvedAttendees}
                    visible={manageAttendeesModalVisible}
                    onCancel={() => setManageAttendeesModalVisible(false)} />
            </div>
            <div className={styles.navBarMobile}>
                {event?.isActive && registrationStatus === 'confirmed' ?
                    <Button loading={isBusy}
                        type='primary'
                        size='large'
                        style={{ marginRight: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className={styles.registerButton}
                        onClick={joinEvent}>
                        Join Event
                        <LiveBadge style={{ marginLeft: 8 }} />
                    </Button>
                    :
                    <div style={{ width: '100%' }}>
                        {registrationStatus !== 'confirmed' ?
                            <Button loading={isBusy}
                                type='primary'
                                size='large'
                                className={styles.registerButton}
                                onClick={attend}>
                                {approvalNeeded ? 'Apply to join' : 'Register'}
                            </Button>
                            :
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={unattend}

                                okText="Yes"
                                cancelText="No">
                                <Button loading={isBusy} size='large' className={styles.registerButton} >
                                    Cancel Registration
                                </Button>
                            </Popconfirm>
                        }
                    </div>
                }
            </div>
            <EventMetrics event={event} visible={metricsModalVisible} onDismiss={() => showMetricsModal(false)} />
            <GuestInviteModal event={event} visible={guestInviteModalVisible} onDismiss={() => showGuestInviteModal(false)} />
        </div>
    )
}

export default inject('sessionStore', 'settingsStore', 'eventsStore', 'membersStore', 'firebase', 'analyticsReporter', 'viewStateStore')(observer(EventInfoPage));