import { Avatar, Drawer, List, Modal, Radio, Tooltip } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import EventModel, { EventFeedback } from "../../../../../common/models/EventModel";
import { inject, observer } from "mobx-react";
import { InfoCircleOutlined, StarOutlined } from '@ant-design/icons';
import styles from './EventMetrics.module.css';
import EventsStore from "../../../../../store/EventsStore";
import _ from "lodash";
import numeral from 'numeral';
import StringUtils from "../../../../../common/utils/StringUtils";
import ViewStateStore from "../../../../../store/ViewStateStore";
import UserModel from "../../../../../common/models/UserModel";
import { ResponsivePie } from '@nivo/pie'
import { RadioChangeEvent } from "antd/lib/radio";
import AttendeeModel from "../../../../../common/models/AttendeeModel";


interface Props {
    visible: boolean;
    event?: EventModel;
    onDismiss?: () => void;
    eventsStore?: EventsStore;
    viewStateStore?: ViewStateStore;
}

function EventMetrics({ visible, onDismiss, event, eventsStore, viewStateStore }: Props) {
    const [busy, setBusy] = useState(false);
    const { isMobile } = viewStateStore!;
    const [feedbacksDrawerVisible, showFeedbacksDrawer] = useState(false);
    const [feedbacks, setFeedbacks] = useState<EventFeedback[]>()
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [filterValue, setFilterValue] = useState<'type' | 'sector' | 'gender' | 'country'>('type');
    const [newVsReapeatedChartData, setNewVsReapeatedChartData] = useState<any[]>([]);
    const [audience, setAudience] = useState<'registered' | 'attended'>('registered')
    const [attendees, setAttendees] = useState<AttendeeModel[]>();

    useEffect(() => {
        if (event) {
            updateStats(event);
        }
    }, [event]);

    useEffect(() => {
        updateCharts(attendees || [], filterValue);
    }, [audience]);

    useEffect(() => {
        updateCharts(attendees || [], filterValue);
    }, [filterValue]);

    const updateStats = async (event: EventModel) => {
        const attendees = await eventsStore!.getAttendees(event.id!);
        const feedbacks = await eventsStore!.getFeedbacks(event.id!);
        setFeedbacks(feedbacks);
        setAttendees(attendees);

        const registered = attendees;
        const attended = attendees.filter(a => a.attended);

        const totalRegistered = registered.length;
        const totalAttended = attended.length;
        const membersRegistered = registered.filter(m => !m.user?.guest).length;
        const membersAttended = attended.filter(m => !m.user?.guest).length;
        const guestsRegistered = registered.filter(m => m.user?.guest).length;
        const guestsAttended = attended.filter(m => m.user?.guest).length;
        const feedbacksCollected = feedbacks.length;
        const feedbackScore = _.meanBy(feedbacks, f => f.score);

        updateCharts(attendees, filterValue);

        await eventsStore?.updateStats(event.id!, {
            totalRegistered,
            totalAttended,
            membersRegistered,
            membersAttended,
            guestsRegistered,
            guestsAttended,
            feedbacksCollected,
            feedbackScore,
        });
    }

    const updateCharts = (attendees: AttendeeModel[], selected: string) => {
        const members = audience === 'attended' ? attendees.filter(a => a.attended) : attendees;

        const byTypeGroups = _.groupBy(members, a => a.user?.type);
        const byTypeData = [];
        for (const type in byTypeGroups) {
            const group = byTypeGroups[type];
            byTypeData.push({
                id: type,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,

            })
        }
        // setTypeChartData(byTypeData);
        if (selected === 'type') {
            setSelectedData(byTypeData);
        }

        const byGenderGroups = _.groupBy(members, a => a.user?.gender);
        const byGenderData = [];
        for (const gender in byGenderGroups) {
            const group = byGenderGroups[gender];
            byGenderData.push({
                id: gender,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        // setGenderChartData(byGenderData);
        if (selected === 'gender') {
            setSelectedData(byGenderData);
        }


        const newVsRepeated = _.groupBy(members, a => a.firstTimer);
        const newVsRepeatedData = [];
        for (const groupKey in newVsRepeated) {
            const group = newVsRepeated[groupKey];
            const label = groupKey === 'true' ? 'New' : 'Repeated';
            newVsRepeatedData.push({
                id: label,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        setNewVsReapeatedChartData(newVsRepeatedData);

        const bySectorGroups = _.groupBy(_.flatMap(members, a => a.user?.primarySectors), sector => sector);
        const bySectorData = [];
        for (const groupKey in bySectorGroups) {
            const group = bySectorGroups[groupKey];
            bySectorData.push({
                id: groupKey,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        // setSectorChartData(bySectorData);
        if (selected === 'sector') {
            setSelectedData(bySectorData);
        }


        const byCountryGroups = _.groupBy(members, a => a.user?.location?.country);
        const byCountryData = [];
        for (const groupKey in byCountryGroups) {
            const group = byCountryGroups[groupKey];
            byCountryData.push({
                id: groupKey,
                label: StringUtils.percentage(group.length, members.length),
                value: group.length,
            })
        }

        // setCountryChartData(byCountryData);
        if (selected === 'country') {
            setSelectedData(byCountryData);
        }

    }

    const onFilterChanged = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setFilterValue(value);
    }

    const onAudienceChanged = (e: RadioChangeEvent) => {
        const value = e.target.value;
        setAudience(value);
    }

    return (<Modal
        centered
        onCancel={onDismiss}
        className={styles.container}
        footer={null}
        width={1100}
        visible={visible}>
        <div className={styles.content}>
            <div className={styles.grid}>
                <div className={styles.metrics}>
                    <div className={styles.sectionHeader}>Total</div>
                    <div className={styles.statsStrip}>
                        <StatsItem title={'Registered'}
                            number={event?.stats?.totalRegistered}
                        />
                        <StatsItem title={'Attended'}
                            number={event?.stats?.totalAttended}
                            sideNoteText={`out of ${event?.stats?.totalRegistered}`}
                            bubbleText={StringUtils.percentage(event?.stats?.totalAttended, event?.stats?.totalRegistered)} />
                    </div>
                    <div className={styles.sectionHeader}>Members</div>
                    <div className={styles.statsStrip}>
                        <StatsItem title={'Registered'}
                            number={event?.stats?.membersRegistered}
                            sideNoteText={event?.stats?.totalMembersAtTheTime ? <span>
                                out of {event?.stats?.totalMembersAtTheTime} members
                                <Tooltip title='At the time of the event.'>
                                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                                </Tooltip>
                            </span> : ''}
                            bubbleText={event?.stats?.totalMembersAtTheTime ? StringUtils.percentage(event?.stats?.totalRegistered, event?.stats?.totalMembersAtTheTime) : ''}
                        />
                        <StatsItem title={'Attended'}
                            number={event?.stats?.membersAttended}
                            sideNoteText={`out of ${event?.stats?.membersRegistered}`}

                            bubbleText={
                                <Tooltip
                                    placement='top'
                                    title={`${StringUtils.percentage(event?.stats?.membersAttended, event?.stats?.totalMembersAtTheTime)} of all members at the time`}>
                                    {StringUtils.percentage(event?.stats?.membersAttended, event?.stats?.membersRegistered)}
                                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                                </Tooltip>
                            } />
                    </div>
                    <div className={styles.sectionHeader}>Guests</div>
                    <div className={styles.statsStrip}>
                        <StatsItem title={'Invited'} number={event?.stats?.guestsRegistered} />
                        <StatsItem title={'Attended'}
                            number={event?.stats?.guestsAttended}
                            sideNoteText={`out of ${event?.stats?.guestsRegistered}`}
                            bubbleText={StringUtils.percentage(event?.stats?.guestsAttended, event?.stats?.guestsRegistered)} />
                    </div>
                    <div className={styles.sectionHeader}>
                        Feedback
                        <div className={styles.viewAll}
                            onClick={() => showFeedbacksDrawer(true)}>
                            View All
                        </div>
                    </div>
                    <div className={styles.statsStrip}>
                        <StatsItem title={'Collected'}
                            number={event?.stats?.feedbacksCollected}
                            sideNoteText={'responses'}
                            bubbleText={StringUtils.percentage(event?.stats?.feedbacksCollected, event?.stats?.totalAttended)} />
                        <StatsItem title={'Avg. Rating'} number={numeral(event?.stats?.feedbackScore).format('0.0')} sideNoteText={'out of 5'} />
                    </div>
                </div>
                <div className={styles.chartsContainer}>
                    <div className={styles.sectionHeader}>

                        Audience

                        <Radio.Group
                            className={styles.audienceRadioGroup}
                            onChange={onAudienceChanged}
                            size='small'
                            defaultValue="registered">
                            <Radio.Button value="registered">Registered</Radio.Button>
                            <Radio.Button value="attended">Attended</Radio.Button>
                        </Radio.Group>
                    </div>

                    <div className={styles.charts}>

                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader} style={{ justifyContent: 'center' }}>
                                {/* <div >Attendees by</div> */}
                                <Radio.Group onChange={onFilterChanged} size='small' defaultValue="type">
                                    <Radio.Button value="type">Type</Radio.Button>
                                    <Radio.Button value="sector">Sector</Radio.Button>
                                    <Radio.Button value="gender">Gender</Radio.Button>
                                    <Radio.Button value="country">Country</Radio.Button>
                                </Radio.Group>
                            </div>
                            <div className={styles.chartContainer}>
                                <ResponsivePie
                                    data={selectedData}
                                    margin={{ top: 40, right: 100, bottom: 30, left: 100 }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    arcLinkLabelsDiagonalLength={8}
                                    arcLinkLabelsStraightLength={8}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                    arcLabel="label"
                                    arcLinkLabel="id"
                                />
                            </div>
                        </div>
                        <div className={styles.chart}>
                            <div className={styles.filterChartHeader}>
                                <div >New vs Repeated</div>
                            </div>
                            <div className={styles.chartContainer}>
                                <ResponsivePie
                                    data={newVsReapeatedChartData}
                                    margin={{ top: 40, right: 60, bottom: 30, left: 60 }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    colors={{ scheme: 'paired' }}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    arcLinkLabelsDiagonalLength={9}
                                    arcLinkLabelsStraightLength={9}
                                    borderWidth={1}
                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                                    arcLabel="label"
                                    arcLinkLabel="id"
                                />
                            </div>
                            {!event?.stats?.totalMembersAtTheTime &&
                                <Tooltip title='Results are more accurate once the event ends.'>
                                    <div>Inaccurate results <InfoCircleOutlined style={{ marginLeft: 4 }} /></div>
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Drawer
            bodyStyle={{ padding: 0, lineHeight: 1.2 }}
            placement='right'
            width={isMobile ? '90%' : 480}
            onClose={() => showFeedbacksDrawer(false)}
            closable={true}
            footer={null}
            visible={feedbacksDrawerVisible}>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={feedbacks}
                renderItem={item => (
                    <List.Item
                        key={item.id}
                        actions={[
                            <div key="list-vertical-star-o" >
                                <StarOutlined style={{ color: '#ffcd12', fontSize: 14 }} />
                                <span style={{ marginLeft: 2, fontWeight: 700, color: '#997606' }}>{item.score}</span>
                            </div>
                        ]}>
                     
                        <List.Item.Meta
                            avatar={<Avatar src={item.by ? item?.by.photoUrl : ''} />}
                            title={item.by ? UserModel.shortName(item.by.firstName, item.by.lastName) : ''}
                            description={item.by ? UserModel.title(item.by.role, item.by.company) : ''}
                        />
                        {item.notes}
                    </List.Item>

                )}
            />
        </Drawer>
    </Modal>);
}

interface StatsItemProps {
    title: string,
    number: number | string | ReactNode,
    sideNoteText?: string | ReactNode
    bubbleText?: string | ReactNode,
}

const StatsItem = ({ title, number, sideNoteText, bubbleText }: StatsItemProps) => {
    return <div className={styles.statContainer}>
        <div className={styles.statTitle}>{title}</div>
        <div className={styles.statsNumbersContainer}>
            <div className={styles.statsBigNumber}>{number}</div>
            <div className={styles.statsSideNote}>{sideNoteText}</div>
            {bubbleText && <div className={styles.bubbleText}>{bubbleText}</div>}
        </div>
    </div>
}



export default inject('eventsStore', 'viewStateStore')(observer(EventMetrics));