import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import FirebaseClient from '../services/FirebaseClient';
import RootStore from './RootStore';
import sectors from '../common/utils/sectors.json';
import _ from 'lodash';
import bioBuilder from '../services/bio/BioBuilder';
import AnalyticsReporter from '../services/analytics/AnalyticsReporter';
import EventModel from '../common/models/EventModel';

class OnboardingStore {
    rootStore: RootStore;
    firebase: FirebaseClient;
    initialized: boolean = false;
    firstNameInputValue?: string;
    photoUrlInputValue?: string;
    lastNameInputValue?: string;
    genderInputValue?: string;
    selectedCompany?: any;
    roleInputValue?: string;
    showAddCompanyDrawer: boolean = false;
    addCompanyDrawerIntialData?: { companyName: string };
    sectorsTags: Sector[] = [];
    selectedSectors: Sector[] = [];
    subSectors?: string[] = [];
    selectedSubSectors?: string[] = [];
    analyticsReporter: AnalyticsReporter;

    constructor(rootStore: RootStore, firebase: FirebaseClient, analyticsReporter: AnalyticsReporter) {
        makeObservable(this, {
            initialized: observable,
            firstNameInputValue: observable,
            lastNameInputValue: observable,
            photoUrlInputValue: observable,
            roleInputValue: observable,
            selectedCompany: observable,
            showAddCompanyDrawer: observable,
            sectorsTags: observable,
            subSectors: observable,
            selectedSectors: observable,
            selectedSubSectors: observable,
            onBasicDetailsSubmit: action,
            setSelectedCompany: action,
            setInitialData: action,
            addCompanyDrawer: action,
            setAddCompanyDrawerIntialData: action,
            onSectorsPrimarySubmit: action,
            onSectorsSecondarySubmit: action,
        })

        this.rootStore = rootStore;
        this.firebase = firebase;
        this.analyticsReporter = analyticsReporter;
    }

    setInitialData(firstName?: string, lastName?: string, photoUrl?: string) {
        this.initialized = true;
        this.firstNameInputValue = firstName;
        this.lastNameInputValue = lastName;
        this.photoUrlInputValue = photoUrl;

        this.sectorsTags = _.sortBy(sectors, a => a.name);
        this.sectorsTags.push({ name: 'Other' });
    }

    onBasicDetailsSubmit = async (firstName: string,
        lastName: string,
        role: string,
        companyInputValue: string,
        gender: string) => {
        this.firstNameInputValue = firstName;
        this.lastNameInputValue = lastName;
        this.roleInputValue = role;
        this.genderInputValue = gender;

        await this.rootStore.sessionStore.updateUser({
            firstName: this.firstNameInputValue,
            lastName: this.lastNameInputValue,
            role: this.roleInputValue,
            gender: this.genderInputValue,
            company: this.selectedCompany || companyInputValue ? {
                id: this.selectedCompany?.objectID,
                name: this.selectedCompany?.name || companyInputValue,
                shortDescription: this.selectedCompany && this.selectedCompany['short-description'],
                slug: this.selectedCompany?.slug,
                logoUrl: this.selectedCompany?.logo?.url,
            } : null
        });

        this.analyticsReporter.setUserBasicDetails(
            firstName + ' ' + lastName,
            this.selectedCompany?.name,
            this.rootStore.sessionStore.authUser?.email || '');
    }

    onUseTypeSubmit = async (type: string) => {
        void this.rootStore.sessionStore.updateUser({
            type: type,
        })

        this.analyticsReporter.setUserType(type);
    }

    onObjectivesSubmit = async (objectives: Objective[]) => {
        const mapped = objectives.map(o => { return { id: o.id } });
        await this.rootStore.sessionStore.updateUser({
            objectives: mapped
        })
    }

    onSectorsPrimarySubmit = async (sectors: Sector[]) => {
        this.selectedSectors = sectors;
        this.subSectors = sectors.map(s => s.subSectors || []).flat();
        await this.rootStore.sessionStore.updateUser({
            primarySectors: sectors.map(s => s.name)
        })
    }

    onSectorsSecondarySubmit = async (subSectors: string[]) => {
        this.selectedSubSectors = subSectors;
        await this.rootStore.sessionStore.updateUser({
            secondarySectors: subSectors
        });
    }

    addNewCompany = async (companyDetails: any) => {
        return this.firebase.addCompany(companyDetails);
    }

    uploadCompanyLogo = async (file: File) => {
        const uploadedImage = await this.firebase.upload(file, 'company_logo');
        return uploadedImage;
    }

    setSelectedCompany = (company: any) => {
        this.selectedCompany = company;
    }

    addCompanyDrawer = (show = true) => {
        this.showAddCompanyDrawer = show;
    }

    markAsCompleted = async () => {
        const { sessionStore } = this.rootStore;
        let bio = '';
        try {
            bio = bioBuilder.build(sessionStore.authUser!)
        } catch (error) {
            console.error(error);
        }

        const { remoteSettingsProvider } = this.rootStore;

        const markAsFoundingMember = await remoteSettingsProvider.foundingMembers();
        const extras = markAsFoundingMember && sessionStore.isLeadersMember ? {
            badges: {
                foundingMember: true
            }
        } : {};

        sessionStore.authUser?.guestInvitationId && await sessionStore.getInvitation(sessionStore.authUser?.guestInvitationId!);

        const currentCohort = this.rootStore.settingsStore.miscellaneous?.currentCohort;

        await sessionStore.updateUser({
            bio: bio,
            onboardingCompleted: true,
            leadersMember: sessionStore.invitation?.isGuest ? false : true,
            ...extras,
            cohort: currentCohort,
            membership: {
                status: 'trial',
                credits: {
                    events: sessionStore.invitation?.membership?.noTrial ? 0 : this.rootStore.settingsStore.membership?.defaultEventCredits || 0,
                    intros: sessionStore.invitation?.membership?.noTrial ? 0 : this.rootStore.settingsStore.membership?.defaultIntroCredits || 0,
                }
            }
        });

        this.firebase.onboardingCompleted(this.rootStore.sessionStore.authUser!);
        sessionStore.addActivityItem('Onboarding completed.', 'profile', 'success');
        this.analyticsReporter.setOnboardingCompleted();
    }

    setAddCompanyDrawerIntialData = (companyName: string) => {
        this.addCompanyDrawerIntialData = { companyName };
    }
}

export type Sector = {
    name: string,
    subSectors?: string[]
}

export type Objective = {
    id: string,
    title: string,
    icon: any,
    checked?: boolean,
}


export default OnboardingStore;


