

import { observable, makeObservable, computed, runInAction, action, makeAutoObservable } from 'mobx';
import moment from 'moment';
import firebase from 'firebase';
import IntroRequestModel, { IntroSource, TimeAvailability } from '../common/models/IntroRequestModel';
import MeetingModel from '../common/models/MeetingModel';
import UserModel, { ConversationModel, UserPublicData } from '../common/models/UserModel';
import FirebaseClient, { FieldValue } from '../services/FirebaseClient';
import RootStore from './RootStore';
import SessionStore from './SessionStore';
import { message } from 'antd';

class ConnectionsStore {
    rootStore: RootStore;
    sessionStore: SessionStore;
    firebase: FirebaseClient;
    userConvesations: ConversationModel[] = [];
    introRequests?: IntroRequestModel[];
    meetings?: MeetingModel[];
    unsubscribe?: () => void;
    unsubscribeMeetings?: () => void;
    followedMembers: UserModel[] = [];
    conversationsListener: () => void = () => { };

    constructor(rootStore: RootStore, firebase: FirebaseClient, sessionStore: SessionStore) {
        makeObservable(this, {
            userConvesations: observable,
            followedMembers: observable,
            introRequests: observable,
            meetings: observable,
            unsubscribe: observable,
            unsubscribeMeetings: observable,
            subscribeToIntroductions: action,
        });

        this.rootStore = rootStore;
        this.sessionStore = sessionStore;
        this.firebase = firebase;
    }

    subscribeToConversations = () => {
        const { authUser } = this.sessionStore
        this.firebase.userConversations(authUser?.id!)
            .orderBy('date', 'desc')
            .onSnapshot((snap) => {
                const conversations = snap.docs.map(d => ConversationModel.mapFromServer({ id: d.id, ...d.data() }));

                runInAction(() => {
                    this.userConvesations = conversations;
                })
            })
    }

    subscribeToIntroductions = () => {
        const unsubscribe = this.firebase.introRequests()
            .orderBy('createdAt', 'desc')
            .limit(50)
            .onSnapshot((snap) => {
                const intros = snap.docs.map(d => IntroRequestModel.mapFromServer({ id: d.id, ...d.data() }));

                runInAction(() => {
                    this.introRequests = intros;
                })
            }, error => {
                console.error(error);
            });

        this.unsubscribe = unsubscribe;
    }

    subscribeToMeetings = () => {
        const unsubscribe = this.firebase.meetings()
            .orderBy('createdAt', 'desc')
            .limit(50)
            .onSnapshot((snap) => {
                const meetingsResult = snap.docs.map(d => MeetingModel.mapFromServer({ id: d.id, ...d.data() }));

                runInAction(() => {
                    this.meetings = meetingsResult;
                })
            }, error => {
                console.error(error);
            });

        this.unsubscribeMeetings = unsubscribe;
    }

    getFollowedMembers = async () => {
        const { authUser } = this.sessionStore;
        if (authUser?.following && authUser?.following.length > 0) {
            const result = await this.firebase.fetchUsers(authUser?.following);
            runInAction(() => {
                this.followedMembers = result;
            })
        }
    }

    getConversation = async (conversationId: string) => {
        const { authUser } = this.sessionStore;
        const conversation = await this.firebase.user(authUser?.id!).collection('conversations').doc(conversationId).get();
        return ConversationModel.mapFromServer({ id: conversation.id, ...conversation.data() })
    }

    getConversationById = async (userId: string, conversationId: string) => {
        const conversation = await this.firebase.user(userId).collection('conversations').doc(conversationId).get();
        return ConversationModel.mapFromServer({ id: conversation.id, ...conversation.data() })
    }

    createOrUpdateConversation = async (conversationId: string, change: any) => {
        const { authUser } = this.sessionStore;
        const conversation = this.firebase.user(authUser?.id!).collection('conversations').doc(conversationId);
        await conversation.set({
            ...change
        }, { merge: true })
    }

    followMember = async (member: UserPublicData) => {
        const { authUser } = this.sessionStore;
        await this.firebase.followUser(member.id!, authUser?.id!);
        this.sessionStore.addActivityItem(`Followed ${UserModel.fullName(member)}`, 'engagement', 'follow');
    }

    unfollowMember = async (member: UserPublicData) => {
        const { authUser } = this.sessionStore;
        await this.firebase.followUser(member.id!, authUser?.id!, false);
        this.sessionStore.addActivityItem(`Unfollowed ${UserModel.fullName(member)}`, 'engagement', 'unfollow');
    }

    saveNotes = async (conversationId: string, notes: string) => {
        await this.createOrUpdateConversation(conversationId, {
            notes: notes
        });
    }


    createIntroduction = async (requested: UserModel, requester: UserModel, source: IntroSource, introducer?: UserModel, purpose?: string) => {
        const { authUser } = this.sessionStore;
        const now = moment().unix();
        const ref = this.firebase.introRequests().doc();
        const autoApprove = source === 'manager';

        const timeline = [{
            message: `Introduction created by ${authUser?.shortName}.`,
            createdAt: now,
            type: 'regular',
        }];

        await ref.set({
            requester: requester.toPublicData(),
            requested: requested.toPublicData(),
            createdAt: now,
            lastUpdate: now,
            purpose: purpose,
            source: source,
            status: autoApprove ? 'pending-member-approval' : 'pending-admin-approval',
            timeline: timeline,
        }, { merge: true });

        if (autoApprove) {
            await this.approveIntroRequest(ref.id, introducer!);
        }

        this.sessionStore.addActivityItem(`Requested intro to ${requested.fullName} (source: ${source})`, 'engagement', 'intro');
        this.firebase.notify(`👋 ${requester.fullName} requested intro to ${requested.fullName}`)
    }

    approveIntroRequest = async (introRequestId: string, selectedIntroducer: UserModel) => {
        const { authUser } = this.sessionStore;
        await this.firebase.updateIntroRequest(introRequestId, {
            introducer: selectedIntroducer.mapToSever(),
            status: 'pending-member-approval',
            timeline: FieldValue.arrayUnion({
                message: `Introduction approved by admin (${authUser?.shortName})`,
                createdAt: moment().unix() + 10,
                type: 'approved',
            })
        });

        return this.firebase.makeIntro(introRequestId);
    }

    rejectIntroRequest = async (introRequestId: string) => {
        const { authUser } = this.sessionStore;
        await this.firebase.updateIntroRequest(introRequestId, {
            status: 'admin-rejected',
            timeline: FieldValue.arrayUnion({
                message: `Admin declined (${authUser?.shortName})`,
                createdAt: moment().unix(),
                type: 'denied',
            })

        });
    }

    deleteIntroduction = async (id: string) => {
        await this.firebase.introRequest(id).delete();
    }

    introductionAccepted = async (introRequest: IntroRequestModel, availableTimes: TimeAvailability[]) => {
        const newTimelineItems = [{
            message: `${introRequest.requested?.firstName} updated availability.`,
            createdAt: moment().unix() + 10,
            type: 'regular',
        }];

        if (!introRequest.requestedApprovedEmailSent) {
            newTimelineItems.unshift({
                message: `${introRequest.requested?.firstName} accepted introduction.`,
                createdAt: moment().unix(),
                type: 'approved',
            })
        }

        await this.firebase.updateIntroRequest(introRequest.id!, {
            status: 'member-accepted',
            requestedTimeAvailability: availableTimes,
            timeline: FieldValue.arrayUnion(...newTimelineItems),
        })

        this.firebase.introAccepted(introRequest.id).then(() => {
            console.log('Intro accpeted');
        });
    }

    introductionCompleted = async (introRequest: IntroRequestModel, selectedTime: TimeAvailability) => {
        const newTimelineItems = [{
            message: `${introRequest.requester?.firstName} accepted available times.`,
            createdAt: moment().unix(),
            type: 'approved',
        }];

        await this.firebase.updateIntroRequest(introRequest.id!, {
            status: 'completed',
            requesterTimeAvailability: [selectedTime],
            timeline: FieldValue.arrayUnion(...newTimelineItems),
        })

        this.firebase.introCompleted(introRequest.id).then(() => {
            console.log('Intro completed');
        });
    }

    checkIfRequestedUserOpenedIntro = async (introRequest: IntroRequestModel, mode?: string) => {
        const { authUser } = this.sessionStore;
        if (authUser && authUser.id !== introRequest.requested?.id) {
            return;
        }

        if (mode === 'requester') {
            return;
        }

        if (!introRequest.requestedOpenedIntro) {
            await this.firebase.updateIntroRequest(introRequest.id!, {
                requestedOpenedIntro: true,
                timeline: FieldValue.arrayUnion({
                    message: `Introduction request opened by ${introRequest?.requested?.firstName}`,
                    createdAt: moment().unix(),
                    type: 'regular',
                })
            });
        }
    }

    checkIfRequesterUserOpenedIntro = async (introRequest: IntroRequestModel, mode?: string) => {
        const { authUser } = this.sessionStore;
        if (authUser && authUser.id !== introRequest.requester?.id) {
            return;
        }

        if (mode === 'requested') {
            return;
        }

        if (!introRequest.requesterOpenedIntro) {
            this.firebase.updateIntroRequest(introRequest.id!, {
                requesterOpenedIntro: true,
                timeline: FieldValue.arrayUnion({
                    message: `Introduction request opened by ${introRequest?.requester?.firstName}`,
                    createdAt: moment().unix(),
                    type: 'regular',
                })
            });
        }
    }

    getIntroductionPreview = async (requestedUser: UserPublicData, requesterUser: UserPublicData, introducer: UserModel, purpose: string) => {
        return this.firebase.introPreview(requestedUser, requesterUser, introducer, purpose);
    }

}


export default ConnectionsStore;


