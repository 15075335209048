import { Avatar, Button, List, message, notification, Popconfirm, Radio, Skeleton, Tabs, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Card from '../../../../common/components/card/Card';
import ControlPanelStore from '../../../../store/ControlPanelStore';
import SessionStore from '../../../../store/SessionStore';
import { ControlPanelHeader } from '../ControlPanel';
import styles from './UsersTab.module.css';
import UserModel from '../../../../common/models/UserModel';
import _ from 'lodash';
import { UserOutlined } from '@ant-design/icons';
import EditUserModal from './EditUserModal';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CSVLink } from "react-csv";
import moment from 'moment';

const csvHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Gender", key: "gender" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
    { label: "Role/Title", key: "role" },
    { label: "Type", key: "type" },
    { label: "Country", key: "country" },
    { label: "Timezone", key: "timezone" },
    { label: "Member", key: "leadersMember" },
    { label: "Cohort", key: "cohort" },
    { label: "Guest", key: "guest" },
    { label: "Staff", key: "staff" },
    { label: "Onboarding Completed", key: "onboardingCompleted" },
    { label: "Welcome Popup Shown", key: "explainersShown" },
    { label: "Registered Meeting", key: "registeredToEvent" },
    { label: "Attended Meeting", key: "attendedToEvent" },
    { label: "Membership Status", key: "membershipStatus" },
    { label: "Membership Initial Start Date", key: "membershipInitialStartDate" },
    { label: "Membership Expiry Date", key: "membershipExpiryDate" },
    { label: "Membership Plan", key: "membershipPlan" },
    { label: "Event Credits Left", key: "eventCredits" },
    { label: "Last Seen", key: "lastSeen" },
    { label: "Created At", key: "createdAt" },
]

interface Props {
    controlPanelStore?: ControlPanelStore;
    sessionStore?: SessionStore;
}

function UsersTab({ controlPanelStore, sessionStore }: Props) {
    const [users, setUsers] = useState(Array<UserModel>());
    const [userToEdit, setUserToEdit] = useState<UserModel>();
    const [filter, setFilter] = useState<'all' | 'members' | 'guests' | 'staff' | 'pending'>('members');

    useEffect(() => {
        controlPanelStore?.fetchAllMembers().then(members => {
            setUsers(members);
        })
    }, []);

    const onUserEditFinished = async () => {
        setUserToEdit(undefined);
        message.success('User details updated!');
        const members = await controlPanelStore?.fetchAllMembers(true);
        setUsers(members || []);
    }

    const onEventTypeChanged = (e: RadioChangeEvent) => {
        const type = e.target.value;
        setFilter(type)
    }

    const getFilteredUsers = () => {
        if (filter === 'all') {
            return users;
        }

        if (filter === 'members') {
            return users.filter(u => u.leadersMember === true);
        }

        if (filter === 'guests') {
            return users.filter(u => u.guest === true);
        }

        if (filter === 'staff') {
            return users.filter(u => u.staff === true);
        }

        if (filter === 'pending') {
            return users.filter(u => !u.onboardingCompleted);
        }
    }

    const mapForCSV = (users: UserModel[]) => {
        return users.map(u => {
            return {
                ...u,
                country: u.location?.country,
                explainersShown: u.explainersShown?.welcome,
                company: u.company?.name,
                lastSeen: moment.unix(u.presence?.lastChanged._seconds).format('M/DD/YYYY HH:mm:ss'),
                attendedToEvent: u.accomplishments?.attendedToEvent,
                registeredToEvent: u.accomplishments?.registeredToEvent,
                lastEventDateAttended: u.accomplishments?.lastEventDateAttended ? moment.unix(u.accomplishments?.lastEventDateAttended).format('M/DD/YYYY HH:mm:ss') : '',
                createdAt: moment.unix(u.createdAt?._seconds).format('M/DD/YYYY HH:mm:ss'),
                membershipStatus: u.membership?.status,
                membershipInitialStartDate: u.membership?.initialStartDate ? moment.unix(u.membership?.initialStartDate?._seconds).format('M/DD/YYYY HH:mm:ss') : '',
                membershipExpiryDate: u.membership?.expiredAt ? moment.unix(u.membership?.expiredAt?.seconds).format('M/DD/YYYY HH:mm:ss') : '',
                membershipPlan: u.membership?.plan,
                eventCredits: u.membership?.status === 'trial' ? u.membership?.credits?.events : '',
            }
        });
    }

    const deleteUser = async (user: UserModel) => {
        await controlPanelStore!.deleteMember(user.id!);
        const members = await controlPanelStore?.fetchAllMembers();
        setUsers(members || []);
    }

    const filteredUsers = getFilteredUsers();

    return (
        <Card className={styles.container}>
            <ControlPanelHeader title={'Users'} />
            <div>Edit and update every user on the platform.</div>
            <div className={styles.filter}>
                <Radio.Group defaultValue={'members'} size='large' onChange={onEventTypeChanged}>
                    <Radio.Button value={'all'}>All</Radio.Button>
                    <Radio.Button value={'members'}>Members</Radio.Button>
                    <Radio.Button value={'staff'}>Staff</Radio.Button>
                    <Radio.Button value={'guests'}>Guests</Radio.Button>
                    <Radio.Button value={'pending'}>Pending Onboarding</Radio.Button>
                </Radio.Group>
                <div className={styles.usersCount}>{filteredUsers?.length} users found</div>
                <Button type='primary' disabled={users.length === 0} className={styles.exportButton}>
                    <CSVLink filename={`export_${filter}_${moment().format('DD/MM/YYYY-HH:mm')}.csv`} data={mapForCSV(getFilteredUsers() || []) || []} headers={csvHeaders} >
                        Export to CSV
                    </CSVLink>
                </Button>
            </div>
            <div className={styles.users}>
                <List
                    itemLayout="horizontal"
                    locale={{ emptyText: `Fetching users...` }}
                    dataSource={filteredUsers}
                    renderItem={user => (
                        <List.Item
                            actions={[
                                <Button type='link' key="edit-user" onClick={() => setUserToEdit(user)}>Edit</Button>
                                // <Popconfirm
                                //     title="Are you sure?"
                                //     onConfirm={() => deleteUser(user)}>
                                //     <Button type='link' key="delete-user">Delete</Button>
                                // </Popconfirm>
                            ]}>
                            <List.Item.Meta
                                avatar={<Avatar src={user.photoUrl} icon={<UserOutlined />} />}
                                title={<div className={styles.title}>
                                    <div className={styles.name}>{_.isEmpty(user.firstName) ? user.email : user.fullName}</div>
                                    <div className={styles.badges}>
                                        {user.isManager && <div className={styles.managerBadge}>Manager</div>}
                                        {user.leadersMember && <div className={styles.leadersBadge}>Member</div>}
                                        {user.isGuest && <div className={styles.guestBadge}>Guest</div>}
                                        {user.isStaff && !user.isManager && <div className={styles.staffBadge}>Staff</div>}
                                        {!user.onboardingCompleted && <div className={styles.pendingBadge}>Pending</div>}
                                    </div>
                                </div>}
                                description={user.title}
                            />
                        </List.Item>
                    )}
                />
            </div>
            {userToEdit && <EditUserModal visible={userToEdit ? true : false}
                onFinish={onUserEditFinished}
                onCancel={() => setUserToEdit(undefined)}
                userModel={userToEdit} />}
        </Card>
    )
}

export default inject('controlPanelStore', 'sessionStore')(observer(UsersTab));