import Checkbox from 'antd/lib/checkbox/Checkbox';
import { AutoComplete, Button, Divider, Form, Input, message, notification, Radio, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../common/components/card/Card';
import SectionHeader from '../../../common/components/sectionHeader/SectionHeader';
import styles from './ProfilePage.module.css'
import ProfilePhoto from '../../onboarding/components/profilePhoto/ProfilePhoto';
import OnboardingStore, { Objective } from '../../../store/OnboardingStore';
import SessionStore from '../../../store/SessionStore';
import CompanyBox from '../../onboarding/components/companyBox/CompanyBox';
import { inject, observer } from 'mobx-react';
import Algolia from '../../../services/Algolia';
import AddCompanyDrawer from '../../onboarding/components/addCompanyDrawer/AddCompanyDrawer';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import sectors from '../../../common/utils/sectors.json';
import _ from 'lodash';
import { objectives } from '../../onboarding/components/objectivsStep/ObjectivesStep';
import MembersStore from '../../../store/MembersStore';
import AnalyticsReporter from '../../../services/analytics/AnalyticsReporter';
import FirebaseClient from '../../../services/FirebaseClient';
const { Option } = AutoComplete;

interface Props {
    onboardingStore: OnboardingStore,
    sessionStore: SessionStore,
    membersStore: MembersStore,
    analyticsReporter: AnalyticsReporter,
    firebase: FirebaseClient,
    algolia: Algolia,
    history: any,
}

function ProfilePage({ onboardingStore, sessionStore, algolia, membersStore, history, analyticsReporter, firebase }: Props) {
    const [hits, setHits] = useState(Array<any>());
    const [companyInputValue, setCompanyInputValue] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [bioValidationStatus, setBioValidationStatus] = useState<ValidateStatus>('');
    const [selectedObjectives, setSelectedObjectives] = useState<Objective[]>([]);
    const { authUser } = sessionStore;
    const [form] = Form.useForm();
    const [accountForm] = Form.useForm();
    const [reAuthRequired, setReAuthRequired] = useState(false);
    const [showEmailUpdateButton, setShowEmailUpdateButton] = useState(false);

    const {
        setSelectedCompany,
        addCompanyDrawer,
        setAddCompanyDrawerIntialData,
        selectedCompany } = onboardingStore;

    useEffect(() => {
        if (authUser) {
            if (authUser.hasCompanyLinked) {
                setSelectedCompany({
                    name: authUser.company?.name,
                    logo: { url: authUser.company?.logoUrl },
                    objectID: authUser.company?.id,
                    'short-description': authUser.company?.shortDescription,
                    slug: authUser.company?.slug,
                })
            } else if (authUser.company?.name) {
                form.setFieldsValue({
                    companySelect: authUser.company.name,
                })
            }
            const selectedObj = _.intersectionBy(objectives, authUser.objectives || [], 'id');
            setSelectedObjectives(selectedObj);
            objectives.forEach(o => o.checked = _.indexOf(selectedObj, o) > -1);
        }
    }, [authUser])

    const onUpdateClicked = () => {
        form.submit();
    }

    const onViewProfile = () => {
        membersStore.viewMember(authUser);
    }

    const onCompanySearch = async (searchValue: string) => {
        if (searchValue.length < 3) {
            return;
        }

        const results: any = await algolia?.search(searchValue);
        results.push({ type: 'add' })
        setHits(results || []);
    }

    const onCompanySelected = async (value: string, option: any) => {
        if (!option.extra) {
            form.setFieldsValue({ companySelect: companyInputValue });
            return;
        }

        const company = option.extra;
        setSelectedCompany(company);
    }

    const onCompanyValueChange = (value: string) => {
        setCompanyInputValue(value);
    }

    const onCompanyUnlink = async () => {
        setSelectedCompany(null);
    }

    const addNewCompany = () => {
        setAddCompanyDrawerIntialData(companyInputValue);
        addCompanyDrawer(true);
    }

    const subSectors = () => {
        const subSectors = sectors.map(s => s.subSectors).flat().filter(s => !_.isEmpty(s));
        return subSectors;
    }

    const onSelected = (objective: Objective) => {
        if (!objective.checked && selectedObjectives.length >= 3) {
            message.info('Please your top most 3 objectives.');
            return;
        }

        objective.checked = !objective.checked;

        if (objective.checked) {
            selectedObjectives.push(objective);
            setSelectedObjectives(_.clone(selectedObjectives));
        } else {
            setSelectedObjectives(selectedObjectives.filter(o => o.id !== objective.id));
        }
    }

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== authUser?.email) {
            setShowEmailUpdateButton(true);
        } else {
            setShowEmailUpdateButton(false);
        }
    }

    const onEmailUpdate = async (values: any) => {
        const { email } = values;
        try {
            await sessionStore.changeEmail(email);
            notification.info({
                placement: 'bottomLeft',
                message: 'Check your email!',
                description: `We've sent a verification email to ${email}. Check your SPAM folder.`
            })
        } catch (error) {
            console.log(error);
            if ((error as any).code === 'auth/requires-recent-login') {
                setReAuthRequired(true);
                return;
            }
            if ((error as any).code === 'auth/email-already-in-use') {
                message.error('This email is already in use by another acount.')
                return;
            }

            message.error('Something went wrong...');
        }

    }

    const onSubmit = async (values: any) => {
        setBioValidationStatus('success');
        setIsBusy(true);
        const userModel = {
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            type: values.type,
            bio: values.bio,
            phoneNumber: values.phoneNumber,
            primarySectors: values.primarySectors,
            secondarySectors: values.secondarySectors,
            company: selectedCompany ? {
                id: selectedCompany.objectID,
                name: selectedCompany.name,
                shortDescription: selectedCompany['short-description'],
                slug: selectedCompany.slug,
                logoUrl: selectedCompany.logo?.url,
            } : companyInputValue ? { name: companyInputValue } : undefined,
            objectives: selectedObjectives.map(o => { return { id: o.id } }),
        };
        await sessionStore.updateUser(userModel);
        setIsBusy(false);

        await firebase.updateUserDataAccossDb({ ...userModel, id: authUser?.id, photoUrl: authUser?.photoUrl } as any)
        notification.success({ message: 'Your profile has been updated!' });
        sessionStore.addActivityItem('Updated profile', 'profile');
    }

    const tagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        return (
            <Tag className={styles.sectorTag} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        );
    }

    if (!authUser) {
        return <div></div>
    }

    return (
        <div className={styles.container}>

            {authUser.isGuest &&
                <div className={styles.goBack} onClick={() => { history.goBack() }} >
                    Go back to the event
                </div>
            }
            <div className={styles.headerContainer}>
                <SectionHeader title='My Profile' subTitle='Update your profile appearance.' />
                <Button loading={isBusy} onClick={onUpdateClicked} size='large' type='primary' className={styles.updateButton}>
                    Update Profile
                </Button>
            </div>

            <Form
                style={{ width: '100%' }}
                form={form}
                className='profile'
                name="profile"
                layout="vertical"
                initialValues={{
                    firstName: authUser.firstName,
                    lastName: authUser.lastName,
                    role: authUser.role,
                    type: authUser.type,
                    bio: authUser.bio,
                    primarySectors: authUser.primarySectors,
                    secondarySectors: authUser.secondarySectors,
                    email: authUser.email,
                    phoneNumber: authUser.phoneNumber,
                }}
                onFinish={onSubmit}
                onFinishFailed={() => message.error(`Some fields are invalid.`)}
            >
                <Card className={styles.card} style={{ marginTop: 30 }}>
                    <Button onClick={onViewProfile} size='large' className={styles.viewProfileButton}>
                        View My Profile
                    </Button>
                    <div className={styles.header}>
                        <div>{`Basic Details`}</div>
                    </div>
                    <Form.Item
                        name='profilePhoto'>
                        <Form.Item>
                            <div className={styles.userProfile}>
                                <ProfilePhoto initialImageUrl={authUser?.photoUrl} />
                                <div className={styles.profileLabels}>
                                    <div className={styles.profileHeaderText}> Profile Photo </div>
                                    <div className={styles.profileSubHeaderText}>Choose a nice picture of yourself.</div>
                                </div>
                            </div>

                        </Form.Item>
                    </Form.Item>

                    <div className={styles.doubleInputGrid}>
                        <Form.Item
                            label='First Name'
                            name="firstName"
                            rules={[{ required: true, message: 'Please enter your first name.' }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                        <Form.Item
                            label='Last Name'
                            name="lastName"
                            rules={[{ required: true, message: 'Please enter your last name.' }]}
                        >
                            <Input size='large' />
                        </Form.Item>
                    </div>

                    <Form.Item label='Company'
                        name='companySelect'>
                        {selectedCompany ?
                            <CompanyBox name={selectedCompany.name} logoUrl={selectedCompany.logo?.url} onUnlink={onCompanyUnlink} />
                            :
                            <AutoComplete size={'large'}
                                onSearch={onCompanySearch}
                                onSelect={onCompanySelected}
                                onChange={onCompanyValueChange}
                            >
                                {hits.map((hit: any) => {
                                    return hit.type === 'add' ?
                                        <Option key='add' value=''>
                                            <div className={styles.addNewButtonLink} onClick={addNewCompany}>+ Add New Company</div>
                                        </Option>
                                        :
                                        <Option key={hit._id} value={hit.name} extra={hit} >
                                            <div className={styles.companySearchHit}>
                                                <img src={hit.logo?.url} className={styles.companySearchLogo} />
                                                <div className={styles.companySearchName}>{hit.name}</div>
                                            </div>
                                        </Option>
                                })}
                            </AutoComplete>
                        }
                    </Form.Item>
                    <Form.Item
                        label='Role'
                        name="role"
                        rules={[{ required: true, message: 'Please enter your role in the company.' }]}
                    >
                        <Input size='large' placeholder={'i.e - CEO, VP Marketing etc.'} />
                    </Form.Item>
                    <Form.Item label='Bio'
                        name='bio'
                        validateStatus={bioValidationStatus}
                        hasFeedback
                        tooltip='Write as if you were introducing yourself.'
                        rules={[{ required: true, message: 'Please add a bio of yourself' }]}>
                        <Input.TextArea maxLength={500} minLength={100} showCount />
                    </Form.Item>
                    <Form.Item label="I'm  a..."
                        name="type">
                        <Radio.Group size='middle'>
                            <Radio.Button value={'startup-founder'}>Startup Founder</Radio.Button>
                            <Radio.Button value={'corporate'}>Corporate Executive</Radio.Button>
                            <Radio.Button value={'investor'}>Investor</Radio.Button>
                            <Radio.Button value={'expert'}>Expert/Advisor</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label='Phone Number'
                        name="phoneNumber"
                        tooltip={'Make sure to include your number with country code (i.e +19159969739)'}
                    >
                        <Input size='large' placeholder='+19159969739' />
                    </Form.Item>
                </Card>
                <Card className={styles.card}>
                    <div className={styles.header} >
                        <div>{`Sectors & Interests`}</div>
                        <div className={styles.subHeader}>Tell us which sectors you're active in.</div>
                    </div>
                    <Form.Item
                        label='Primary sectors'
                        name="primarySectors"
                        rules={[{ required: true, message: 'Please select at least one sector.' }]}
                    >
                        <Select tagRender={tagRender} mode="multiple" style={{ width: '100%' }} placeholder="Select primary sectors">
                            {sectors.map(s => <Select.Option key={s.name} value={s.name}>{s.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Secondary sectors'
                        name="secondarySectors"
                        rules={[{ required: true, message: 'Please select at least one sector.' }]}
                    >
                        <Select tagRender={tagRender} mode="multiple" style={{ width: '100%' }} placeholder="Select secondary sectors">
                            {subSectors().map(s => <Select.Option key={s} value={s || ''}>{s}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Card>
                <Card className={styles.card}>
                    <div className={styles.header} >
                        <div>{`Goals & Objectives`}</div>
                        <div className={styles.subHeader}>This will help us to connect you with right people and content.</div>
                    </div>
                    <div className={styles.objectivesContainer}>
                        {
                            objectives.map(objective => {
                                return <div key={objective.id}
                                    className={`${styles.objectiveButton} ${objective.checked ? styles.objectiveSelected : ''}`}
                                    onClick={() => onSelected(objective)}>
                                    <img src={objective.icon} className={styles.icon} />
                                    <div>{objective.title}</div>
                                </div>
                            })
                        }
                    </div>

                </Card>

            </Form>
            <Card className={styles.card}>
                <div className={styles.header} >
                    <div>{`Account`}</div>
                </div>
                <Form
                    style={{ width: '100%' }}
                    form={accountForm}
                    initialValues={{
                        email: authUser.email
                    }}
                    className='accountForm'
                    name="accountForm"
                    layout="vertical"
                    onFinish={onEmailUpdate}>
                    <Form.Item
                        label='Email'
                        name="email"
                        rules={[{ type: 'email', required: true, message: 'Please enter a valid email.' }]}
                    >
                        <Input onChange={onEmailChanged} size='large' placeholder={'john@traveltechnation.com'} />
                    </Form.Item>
                    {!reAuthRequired && showEmailUpdateButton &&
                        <Button type='primary'
                            className={styles.updateEmailButton}
                            onClick={() => accountForm.submit()}>
                            Update Email
                        </Button>
                    }

                    {reAuthRequired &&
                        <div className={styles.reauthContainer}>
                            <div className={styles.labelDescription}>This operation is sensitive - please login again using your current email: <b>{authUser?.email}.</b></div>
                            <Button onClick={() => sessionStore.logout()} className={styles.reloginButtton}>Login Again</Button>
                        </div>
                    }

                </Form>

            </Card>
            <Button loading={isBusy}
                onClick={onUpdateClicked}
                size='large'
                type='primary'
                className={styles.updateButton}>
                Update Profile
            </Button>
            <AddCompanyDrawer />
        </div >
    )
}

export default inject('onboardingStore', 'algolia', 'analyticsReporter', 'sessionStore', 'membersStore', 'analyticsReporter', 'firebase')(observer(ProfilePage));
