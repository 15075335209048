import { Typography } from 'antd';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';
import UserAvatar from '../../../../../common/components/avatar/UserAvatar';
import Card from '../../../../../common/components/card/Card';
import UserModel from '../../../../../common/models/UserModel';
import SessionStore from '../../../../../store/SessionStore';
import styles from './UserBox.module.css';

interface Props {
    userModel?: UserModel,
    style?: any,
    onClick?: (userModel: UserModel) => void;
    showOnline?: boolean;
    sessionStore?: SessionStore;
}

function UserBox({ onClick, userModel, style, showOnline = false, sessionStore }: Props) {
    const { authUser } = sessionStore!;

    return (
        <div className={styles.container} onClick={() => onClick && userModel && onClick(userModel)} style={style}>
            <Card className={styles.card}>
                {authUser?.isManager && userModel?.hasActiveMembership &&
                    <div className={styles.premiumBadge} style={userModel.hasExpiredMembership ? { backgroundColor: 'red'}: {}}>
                        {  userModel.hasExpiredMembership ? 'Expired Premium' : 'Premium' }
                    </div>
                }
                <UserAvatar online={showOnline && userModel?.isOnline} size={'large'} userImage={userModel?.photoUrl} companyImage={userModel?.company?.logoUrl} />
                <div className={styles.text}>
                    <div className={styles.name}>
                        <span>{userModel?.fullName}</span>
                    </div>
                    <div className={styles.titles}>
                        <Typography.Paragraph ellipsis={{ rows: 2, expandable: false }} className={styles.title}>{userModel?.role}</Typography.Paragraph>
                        <div className={styles.companyName}>
                            {userModel?.company?.name}
                        </div>
                    </div>
                </div>
                {userModel?.isGuest &&
                    <div className={styles.role}>Guest</div>
                }
            </Card>
        </div>
    )
}

export default inject('sessionStore')(observer(UserBox));