import 'antd/dist/antd.css'
import './index.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from 'mobx-react'
import RootStore from './store/RootStore';
import DashboardPage from './pages/dashboard/DashboardPage';
import LoginPage from './pages/login/LoginPage';
import OnboardingPage from './pages/onboarding/OnboardingPage';
import WaitingListPage from './pages/waitingList/WaitingListPage';
import UpcomingPage from './pages/upcoming/UpcomingPage';
import AddToCalendarPage from './pages/addToCalendar/AddToCalendarPage';
import GuestConfirmationPage from './pages/guestConfirmation/GuestConfirmationPage';
import { configure } from 'mobx';
import AccountUpdatePage from './pages/accountUpdate/AccountUpdatePage';
import IntroSchedulePage from './pages/introSchedule/IntroSchedulePage';
import LiveEventPage from './pages/dashboard/events/components/liveEvent/LiveEventPage';
import CheckoutPage from './pages/checkout/CheckoutPage';
import Loader from './common/components/loader/Loader';
import { useEffect } from 'react';
import EventRedirectPage from './pages/dashboard/events/components/eventRedirect/EventRedirectPage';
import MembershipCardPage from './pages/membershipCard/MembershipCardPage';

configure({ isolateGlobalState: true })

interface Props {
  rootStore: RootStore,
}

function App({ rootStore }: Props) {
  const [spaceSettingsLoaded, setSpaceSettingsLoaded] = useState(false);

  rootStore.settingsStore.onSettingsChanged((settings) => {
    setSpaceSettingsLoaded(settings ? true : false);
  })

  if (!spaceSettingsLoaded) {
    return <div className='loaderContainer'>
      <Loader visible />
    </div>
  }

  return (
    <Provider {...rootStore}>
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/waiting-list" component={WaitingListPage} />
          <Route path="/upcoming" component={UpcomingPage} />
          <Route path="/invitation/:invitationId" component={LoginPage} />
          <Route path="/guest-invitation/:invitationId" component={LoginPage} />
          <Route path="/guest-confirmation" component={GuestConfirmationPage} />
          <Route path="/onboarding" component={OnboardingPage} />
          <Route path="/add-to-calendar/:eventId" component={AddToCalendarPage} />
          <Route path={`/schedule/:introRequestId`} component={IntroSchedulePage} />
          <Route path={`/live/:eventId`} component={EventRedirectPage} />
          <Route path={`/space/:eventId`} component={LiveEventPage} />
          <Route path="/account-update" component={AccountUpdatePage} />
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/mc/:userId" component={MembershipCardPage} />
          <Route path="/" component={DashboardPage} />
        </Switch>
      </Router>

    </Provider>
  );
}

export default App;
