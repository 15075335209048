
import { Form, Input, message, Modal, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ImageUpload from '../../../../../common/components/imageUpload/ImageUpload';
import { Speaker } from '../../../../../common/models/EventModel';
import styles from './AddSpeakerModal.module.css';

interface Props {
    visible: boolean;
    onCreate: (value: Speaker) => void;
    onCancel: () => void;
}

function AddSpeakerModal({ visible, onCancel, onCreate }: Props) {
    const [form] = Form.useForm();
    const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
    const [companyLogoUrl, setCompanyLogoUrl] = useState('');

    const onProfileUploadFinish = (imageUrl: any) => {
        setProfilePhotoUrl(imageUrl);
    }
    const onCompanyUploadFinish = (imageUrl: any) => {
        setCompanyLogoUrl(imageUrl);
    }

    const onConfirm = () => {
        form.validateFields()
            .then((values: any) => {
                if (!profilePhotoUrl) {
                    message.error('Please add company logo.')
                    return;
                }

                onCreate({ ...values, photoUrl: profilePhotoUrl, companyLogoUrl: companyLogoUrl });
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            visible={visible}
            title="Add new speaker"
            okText="Add Speaker"
            cancelText="Cancel"
            centered
            onCancel={onCancel}
            onOk={onConfirm}
        >
            <Form
                form={form}
                initialValues={{ role: 'speaker' }}
                layout="vertical"
                name="addSpeakersForm"
            >
                <Form.Item
                    name="photoUrl"
                    label="Speaker Photo"
                >
                    <ImageUpload prefix='speaker_profile' onUploadFinish={onProfileUploadFinish} />
                </Form.Item>
                <div className={'doubleInputGrid'}>
                    <Form.Item
                        name="name"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please add name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[{ required: true, message: 'Please add role' }]}
                    >
                        <Input />
                    </Form.Item>
                </div>
                <div className='doubleInputGrid'>
                    <Form.Item
                        name="companyName"
                        label="Company Name"
                        rules={[{ required: true, message: 'Please add company' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                    name="companyLogoUrl"
                    label="Company Logo">
                    <ImageUpload prefix='company_logo' onUploadFinish={onCompanyUploadFinish} />
                </Form.Item>
                </div>
                <Form.Item
                    name="role"
                    label="Role In this event"
                    rules={[{ required: true, message: 'Please select a role' }]}
                >
                    <Select size='large'>
                        <Select.Option value={'host'}>Host</Select.Option>
                        <Select.Option value={'speaker'}>Speaker</Select.Option>
                        <Select.Option value={'panelist'}>Panelist</Select.Option>
                        <Select.Option value={'expert'}>Expert</Select.Option>
                        <Select.Option value={'guest'}>Guest</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>)
}

export default AddSpeakerModal;