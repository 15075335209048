import SessionStore from "./SessionStore";
import FirebaseClient from '../services/FirebaseClient'
import ViewStateStore from "./ViewStateStore";
import MembersStore from "./MembersStore";
import OnboardingStore from "./OnboardingStore";
import Algolia from "../services/Algolia";
import EventsStore from "./EventsStore";
import AnalyticsReporter from "../services/analytics/AnalyticsReporter";
import RemoteSettingsProvider from "../services/settings/RemoteSettingsProvider";
import ControlPanelStore from "./ControlPanelStore";
import EventStore from "./EventStore";
import VideoRoomStore from "./VideoRoomStore";
import MeetingStore from "./MeetingStore";
import ConnectionsStore from "./ConnectionsStore";
import SettingsStore from "./SettingsStore";
import ResourcesStore from "./ResourcesStore";

class RootStore {
    sessionStore: SessionStore;
    viewStateStore: ViewStateStore;
    membersStore: MembersStore;
    eventsStore: EventsStore;
    onboardingStore: OnboardingStore;
    controlPanelStore: ControlPanelStore;
    firebase: FirebaseClient;
    analyticsReporter: AnalyticsReporter;
    algolia: Algolia;
    remoteSettingsProvider: RemoteSettingsProvider;
    eventStore: EventStore;
    videoRoomStore: VideoRoomStore;
    meetingStore: MeetingStore;
    connectionsStore: ConnectionsStore;
    resourcesStore: ResourcesStore;
    settingsStore : SettingsStore;

    constructor() {
        this.algolia = new Algolia();
        this.analyticsReporter = new AnalyticsReporter();
        this.firebase = new FirebaseClient(this, this.analyticsReporter);
        this.remoteSettingsProvider = new RemoteSettingsProvider()
        this.settingsStore = new SettingsStore(this, this.firebase);
        this.sessionStore = new SessionStore(this, this.firebase, this.analyticsReporter, this.settingsStore, this.remoteSettingsProvider);
        this.viewStateStore = new ViewStateStore(this, this.settingsStore);
        this.onboardingStore = new OnboardingStore(this, this.firebase, this.analyticsReporter);
        this.membersStore = new MembersStore(this, this.firebase);
        this.eventsStore = new EventsStore(this, this.sessionStore, this.firebase, this.settingsStore);
        this.controlPanelStore = new ControlPanelStore(this, this.firebase);
        this.eventStore = new EventStore(this, this.sessionStore ,this.firebase);
        this.videoRoomStore = new VideoRoomStore(this);
        this.meetingStore = new MeetingStore(this, this.firebase, this.sessionStore);
        this.connectionsStore = new ConnectionsStore(this, this.firebase, this.sessionStore);
        this.resourcesStore = new ResourcesStore(this, this.firebase);
    }
}


export default RootStore;


