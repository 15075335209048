import React from "react";
import Card from "../card/Card";
import { inject, observer } from "mobx-react";
import { CloseOutlined } from '@ant-design/icons';
import styles from './CommunityChatCard.module.css';
import SessionStore from "../../../store/SessionStore";
import { Button } from "antd";
import SettingsStore from "../../../store/SettingsStore";
import ViewStateStore from "../../../store/ViewStateStore";
import dicordLogo from './discord-logo.png';


interface Props {
    className?: string,
    sessionStore?: SessionStore;
    settingsStore?: SettingsStore;
    viewStateStore?: ViewStateStore;
}

function CommunityChatCard({ className, sessionStore, settingsStore, viewStateStore }: Props) {
    const { authUser } = sessionStore!;

    const onClick = () => {
        window.open(settingsStore?.miscellaneous?.chatLink);
    }

    const onDismiss = () => {
        sessionStore?.updateUser({
            settings: {
                hideChatCard: true,
            }
        })
    };

    return (<Card className={`${styles.container} ${className}`}>
        <div className={styles.dismissIcon} onClick={onDismiss}><CloseOutlined /></div>
        <div className={styles.header}>Join our community chat! 👋</div>
        <div className={styles.description}>Connect with other fellows and get up-to-date with everything happens in our community.</div>
        <div className={styles.buttonContainer}>
            <Button onClick={onClick} className={styles.ctaButton} type={'primary'} size={'large'}><img src={dicordLogo} className={styles.icon} /> Join Discord Chat</Button>
        </div>
    </Card>);
}


export default inject('sessionStore', 'settingsStore', 'viewStateStore')(observer(CommunityChatCard));