
import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import EventModel from '../../../../../common/models/EventModel';
import makeUrls, { CalendarEvent } from '../../../../../common/utils/icsHelper';

interface Props {
    event?: EventModel,
    className?: string,
}

function AddToCalendar({ event, children, className }: React.PropsWithChildren<Props>) {
    const [calendars, setCalendars] = useState(Array<any>());
    useEffect(() => {
        if(!event) {
            return;
        }
        
        const calendarEvent = {
            name: event?.title || '',
            details: event.type === 'online' ? `ℹ️ For details, go here: ${window.location.protocol}//${window.location.host}/e/${event?.shortId} \\n🎉 To access the live online event, go here: ${window.location.protocol}//${window.location.host}/live/${event?.shortId}` : `In-person meeting at ${event?.locationString}. \\n ℹ️ For details, go here: ${window.location.protocol}//${window.location.host}/e/${event?.shortId}` ,
            location: event?.locationString || 'Online (Link attached in the description)',
            startsAt: event?.localizedDate(event.startDate)?.format() || '',
            endsAt: event?.localizedDate(event.endDate)?.format() || ''
        };

        setCalendars(makeUrls(calendarEvent));
    }, [event])


    return (
        <Dropdown trigger={['click']} className={className} overlay={
            < Menu >
                {
                    calendars.map(c => {
                        return <Menu.Item key={c.title}>
                            <a target="_blank" download={'download'} rel="noopener noreferrer" href={c.url}>
                                {c.title}
                            </a>
                        </Menu.Item>
                    })
                }
            </Menu >
        }>
            { children}
        </Dropdown >
    )

}

export default AddToCalendar;