import { Avatar, Tooltip, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import Card from "../../../../../common/components/card/Card";
import EventModel from "../../../../../common/models/EventModel";
import LiveBadge from "../liveBadge/LiveBadge";
import AttendanceEncourager from "./components/attendanceEncourager/AttendanceEncourager";
import PeopleGoingSnippet from "./components/peopleGoingSnippet/PeopleGoingSnippet";
import EventCardSpeaker, { EventCardSpeakerExtended } from "./components/speaker/EventCardSpeaker";
import styles from './EventCard.module.css';
import { VideoCameraOutlined } from '@ant-design/icons';
import { observe } from "mobx";
import { inject, observer } from "mobx-react";
import SessionStore from "../../../../../store/SessionStore";
const MAX_SPEAKERS_TO_SHOW = 4;

interface Props {
    eventModel: EventModel;
    sessionStore?: SessionStore
    className?: string;
    onClick?: () => void;
}

function EventCard({ eventModel, sessionStore, className }: Props) {
    const history = useHistory();
    const { authUser } = sessionStore!;

    const isRegistered = eventModel.attendeesIds?.includes(authUser?.id || '') || false;

    const onClick = () => {
        history.push(`/e/${eventModel.shortId}`, { event: eventModel! });
    }

    return <div onClick={onClick} className={className}>
        {eventModel.themeBackground ?
            <ForumEventCard eventModel={eventModel} isRegistered={isRegistered} />
            : <RegularEventCard eventModel={eventModel} />
        }
    </div>


}


function ForumEventCard({ eventModel, isRegistered }: { eventModel: EventModel, isRegistered: boolean }) {
    const speakers = eventModel.speakers!;
    const extraSpeakers = speakers.length - MAX_SPEAKERS_TO_SHOW;
    const captainOfIndustryEvent = eventModel.themeTitle && eventModel.themeTitle?.indexOf('Captain of Industry') > -1;
    return <Card className={`${styles.container}`}
        style={{
            backgroundColor: eventModel.themeColor,
            backgroundImage: `url("${eventModel.themeBackground}")`
        }}>
        <div className={styles.content}>
            <div>
                <p className={styles.paddedHeader}>
                    {eventModel.title}
                </p>
            </div>

            <div className={styles.dateContainer}>
                <span className={styles.forumDate}>
                    {eventModel.longDateString}
                </span>
                <span>
                    {eventModel.isActive && <LiveBadge className={styles.liveBadge} />}
                    {eventModel.isEnded && !eventModel.recordUrl && <div className={styles.endedBadge}>Ended</div>}
                    {eventModel.isEnded && eventModel.recordUrl && <div className={styles.endedBadge}><VideoCameraOutlined className={styles.videoIcon} /> Recording Available </div>}
                    {isRegistered && <div className={styles.registeredBadge}>Registered</div>}
                </span>
            </div>
            {!eventModel.isEnded &&
                <AttendanceEncourager event={eventModel} className={styles.encourager} />
            }
            <div className={styles.speakersContainer}>
                {!captainOfIndustryEvent && eventModel.speakers && eventModel.speakers.length === 1 &&
                    <div className={styles.speakers}>
                        <EventCardSpeakerExtended theme='light' speaker={speakers[0]} />
                    </div>}
                {!captainOfIndustryEvent && eventModel.speakers && eventModel.speakers.length > 1 &&
                    <div className={styles.speakers}>
                        {_.take(speakers, MAX_SPEAKERS_TO_SHOW)?.map((speaker, index) => {
                            return <EventCardSpeaker theme='light' key={index} speaker={speaker} />
                        })}
                        {extraSpeakers > 0 && <div style={{ color: 'white' }} className={styles.extraSpeakers}>+{extraSpeakers}</div>}
                    </div>
                }
            </div>

            <PeopleGoingSnippet
                attendessSnippet={eventModel.attendeesSnippet}
                count={eventModel.attendeesCount}
                className={styles.forumMembersGoing} style={eventModel.speakers?.length === 0 ? { position: 'absolute', bottom: 20 } : {}} />
        </div>
        {eventModel.isHidden &&
            <Tooltip title='Only staff and admins can see this event'>
                <div className={styles.hiddenIndicator}>
                    Hidden from members
                </div>
            </Tooltip>
        }
    </Card>
}


function RegularEventCard({ eventModel }: Props) {
    const speakers = eventModel.speakers!;
    const extraSpeakers = speakers.length - MAX_SPEAKERS_TO_SHOW;
    return <Card className={`${styles.container}`}>
        <div className={styles.themeStrip} style={{ backgroundColor: eventModel.themeColor }} />
        <div className={styles.content}>
            <div className={styles.themeTitle} style={{ color: eventModel.themeColor }}>{eventModel.themeTitle}</div>
            <Typography.Paragraph className={styles.title}>
                {eventModel.title}
            </Typography.Paragraph>
            <div className={styles.dateContainer}>
                <span className={styles.date}>{eventModel.longDateString}</span>
                <span>
                    {eventModel.isActive && <LiveBadge className={styles.liveBadge} />}
                    {eventModel.isEnded && !eventModel.recordUrl && <div className={styles.endedBadge}>Ended</div>}
                    {eventModel.isEnded && eventModel.recordUrl && <div className={styles.endedBadge}><VideoCameraOutlined className={styles.videoIcon} /> Recording Available </div>}
                </span>
            </div>
            {!eventModel.isEnded &&
                <AttendanceEncourager event={eventModel} className={styles.encouragerLight} />
            }
            {eventModel.speakers && eventModel.speakers.length > 0 &&
                <div className={styles.speakersContainer}>
                    {/* <div className={styles.speakersHeader}>{`Featuring:`}</div> */}
                    <div className={styles.speakers}>
                        {_.take(speakers, MAX_SPEAKERS_TO_SHOW)?.map((speaker, index) => {
                            return <EventCardSpeaker key={index} speaker={speaker} />
                        })}
                        {extraSpeakers > 0 && <div className={styles.extraSpeakers}>+{extraSpeakers}</div>}
                    </div>
                </div>}
            <PeopleGoingSnippet
                attendessSnippet={eventModel.attendeesSnippet}
                count={eventModel.attendeesCount}
                className={styles.membersGoing} />
        </div>
        {eventModel.isHidden &&
            <Tooltip title='Only staff and admins can see this event'>
                <div className={styles.hiddenIndicator}>
                    Hidden from members
                </div>
            </Tooltip>
        }
    </Card>
}


export default inject('sessionStore')(observer(EventCard));

