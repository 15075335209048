import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styles from './MembershipCardPage.module.css'
import SessionStore from '../../store/SessionStore';
import MembersStore from '../../store/MembersStore';
import UserModel from '../../common/models/UserModel';
import SettingsStore from '../../store/SettingsStore';
import { hideTawkChatBubble, isMobile } from '../../common/utils/Utils';
import UserAvatar from '../../common/components/avatar/UserAvatar';
import Lottie from 'react-lottie';
import cardBg from './card-bg.json';
import moment from 'moment';
import ControlPanelStore from '../../store/ControlPanelStore';
import EventModel from '../../common/models/EventModel';
import { MailOutlined, GlobalOutlined, PhoneOutlined } from '@ant-design/icons';
import FirebaseClient from '../../services/FirebaseClient';
import CompanyModel from '../../common/models/CompanyModel';
import { settings } from 'cluster';

const lottieFile = {
    loop: true,
    autoplay: true,
    animationData: cardBg,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

interface Props {
    sessionStore: SessionStore;
    settingsStore: SettingsStore;
    membersStore: MembersStore;
    controlPanelStore: ControlPanelStore;
    firebase: FirebaseClient;
}

function MembershipCardPage({ controlPanelStore, membersStore, firebase, settingsStore }: Props) {
    const { space } = settingsStore;
    const location = useLocation();
    const [user, setUser] = useState<UserModel>();
    const [events, setEvents] = useState<{ item: EventModel, attended: boolean }[]>();
    const [company, setCompany] = useState<CompanyModel>();
    const match = useRouteMatch();
    const history = useHistory();


    useEffect(() => {
        const { userId } = match.params as any;

        if (userId) {
            membersStore.fetchById(userId).then(user => {
                setUser(user);
            })
        }

        hideTawkChatBubble();
    }, []);

    useEffect(() => {
        if (user) {
            controlPanelStore.fetchUserEvents(user.id!).then(result => {
                setEvents(result);
            })

            if (user.company?.slug) {
                firebase.getCompanyById(user.company.slug!).then(c => {
                    setCompany(c);
                })
            }
        }
    }, [user]);

    const meetingAttented = () => {
        return events?.filter(e => e.attended || e.item.type === 'physical');
    }

    const websiteTo = () => {
        window.open(`${company?.website}`, '_blank')
    }

    const onEdit = () => {
        history.push('/profile');
    }

    return (
        <div className={styles.container}>
            <div className={styles.upperPart}>
                <div className={styles.background}>
                    <Lottie height={800} options={lottieFile} />
                </div>

                <div className={styles.upperPartBody}>
                    {user?.hasActiveMembership &&
                        <div className={styles.premiumBadge}>
                            Premium Member
                        </div>
                    }
                    <img onClick={() => window.open(space?.websiteUrl, '_blank')} src={space?.logo} className={styles.logo}/>
                    <UserAvatar size={146} userImageClass={styles.avatar} userImage={user?.photoUrl} companyImage={user?.company?.logoUrl} />
                    <div className={styles.userDetails}>
                        <div className={styles.name}>{user?.fullName}</div>
                        <div className={styles.title}>{user?.title}</div>
                    </div>
                    {user?.badges?.foundingMember && <div className={styles.foundingMemberBadge}>🌱 Founding Member</div>}
                    <div className={styles.contactDetails}>
                        <a href={`mailto:${user?.email}`}><div className={styles.circle}><MailOutlined /></div></a>
                        {user?.phoneNumber && <a href={`tel:${user?.phoneNumber}`}><div className={styles.circle}><PhoneOutlined /></div></a>}
                        {company && <div onClick={websiteTo} className={styles.circle}><GlobalOutlined /></div>}
                    </div>
                </div>
            </div>
            <div className={styles.bottomPart}>
                <div className={styles.grid}>
                    <div className={styles.card}>
                        <div className={styles.sideIcon}>📅</div>
                        <div className={styles.date}>{user?.membership?.expiredAt ? moment.unix(user?.membership?.expiredAt?.seconds).format('DD/MM/YY') : 'None'}</div>
                        <div className={styles.smallHeader}>Expiry Date</div>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.sideIcon}>🏆</div>
                        <div className={styles.largeText}>{meetingAttented()?.length || 0}</div>
                        <div className={styles.smallHeader}>Meetings Attended</div>
                    </div>
                </div>
                <div className={styles.editProfile} onClick={onEdit}>Edit your profile</div>
            </div>
        </div>
    )
}

export default inject('sessionStore', 'settingsStore', 'membersStore', 'controlPanelStore', 'firebase')(observer(MembershipCardPage));