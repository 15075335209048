import React from "react";
import styles from './PeopleGoingSnippet.module.css';
import { Avatar } from 'antd';
import voltageIcon from './voltage.png';
import { UserOutlined } from '@ant-design/icons';

export const MIN_ATTENDEES_TO_SHOW_SNIPPET = 4;
export const MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER = 20;

interface Props {
    attendessSnippet?: string[];
    count?: number,
    className?: string;
    style?: any;
}

function PeopleGoingSnippet({ attendessSnippet, count = 0, className, style }: Props) {
    if (count < MIN_ATTENDEES_TO_SHOW_SNIPPET) {
        return <></>;
    }

    return <div className={`${className} ${styles.membersGoginContainer}`} style={style}>
        <div>
            <Avatar.Group >
                {attendessSnippet?.filter(url => url).map((attendeePhotoUrl, index) => {
                    return <Avatar key={index} className={styles.teamAvatar} src={attendeePhotoUrl} />
                })}
            </Avatar.Group>
        </div>
        <div className={styles.membersGogingText}>
            {count >= MIN_ATTENDEES_TO_SHOW_EXACT_NUMBER ? `${count} people are going`
                :
                <div>
                    <img src={voltageIcon} className={styles.icon} />
                    <span>Seats are getting filled!</span>
                </div>
            }
        </div>
    </div>
}

export default PeopleGoingSnippet;
