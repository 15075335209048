import { Button, Drawer, notification } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CloseOutlined, LineChartOutlined, EditOutlined } from '@ant-design/icons';
import MembersStore from '../../../../store/MembersStore';
import SessionStore from '../../../../store/SessionStore';
import ViewStateStore from '../../../../store/ViewStateStore';
import UserDrawerHeader from './header/UserDrawerHeader';
import styles from './UserDrawer.module.css';
import CompanyInfo from './companyInfo/CompanyInfo';
import foundingMemberBadge from './badges/foundingBadge.png';
import moderatorBadge from './badges/moderatorBadge.png';
import { isWindows } from "react-device-detect";
import MeetMemberModal from '../../members/components/meetMemberModal/MeetMemberModal';
import RequestIntroModal from '../../members/components/requestIntroModal/RequestIntroModal';
import MemberMetricsModal from './metrics/MemberMetricsModal';
import EditUserModal from '../../admin/membersTab/EditUserModal';
import UserModel from '../../../../common/models/UserModel';
const flag = require('country-code-emoji');


interface Props {
    sessionStore?: SessionStore,
    membersStore?: MembersStore,
    viewStateStore?: ViewStateStore,
    history: any,
}

function UserDrawer({ membersStore, viewStateStore, sessionStore, history }: Props) {
    const { currentViewedMember, openUserDrawer, userDrawerOpen } = membersStore!;
    const { isMobile } = viewStateStore!;
    const { authUser } = sessionStore!;
    const [meetMemberModalVisible, setMeetMemberModalVisible] = useState(false);
    const [requestIntroModalVisible, setRequesIntroModalVisible] = useState(false);
    const [memberMetricsVisible, setMemberMetricsVisible] = useState(false);
    const [editMemberModalVisible, setEditMemberModalVisible] = useState(false);
    const [userToEdit, setUserToEdit] = useState<UserModel>();

    useEffect(() => {
        if (currentViewedMember) {
            //window.history.replaceState(null, '', `/member/${currentViewedMember.id}`)
            //history.push(`/member/${currentViewedMember.id}`)
        }

    }, [currentViewedMember]);

    useEffect(() => {
        return history.listen((location: any) => {
            if (history.action === 'POP') {
                openUserDrawer(false);
            }
        })
    }, [])

    const onClose = () => {
        //history.go(-1);
        openUserDrawer(false)
    }

    const onCompanyClicked = (companyId?: string) => {
        openUserDrawer(false);
        history.push(`/company/${companyId}`)
    }

    const onMeetClicked = () => {
        setMeetMemberModalVisible(true);
    }

    const onRequestIntroClicked = () => {
        setRequesIntroModalVisible(true);
    }

    const onUserEdit = async () => {
        const user = await membersStore?.fetchById(currentViewedMember!.id!);
        setUserToEdit(user);
    }

    const isSelf = authUser?.id == currentViewedMember?.id;

    return (
        <Drawer
            className={styles.container}
            bodyStyle={{ padding: 0, lineHeight: 1.2 }}
            placement='right'
            width={isMobile ? '90%' : 660}
            onClose={onClose}
            closable={true}
            closeIcon={<CloseOutlined style={{ color: 'white' }} />}
            visible={userDrawerOpen}>
            <UserDrawerHeader userModel={currentViewedMember} />
            <div className={styles.buttonContainer}>
                {!isSelf &&
                    <Button className={styles.introButton} type='primary' onClick={onRequestIntroClicked}>
                        Request Intro
                    </Button>
                }
                {!isSelf &&
                    <Button className={styles.meetButton} type='default' onClick={onMeetClicked}>
                        Meet {currentViewedMember?.firstName}
                    </Button>
                }
                {authUser?.isStaff &&
                    <Button className={styles.metricsButton} type='default' onClick={() => setMemberMetricsVisible(true)}>
                        <LineChartOutlined /> Metrics
                    </Button>
                }
                {authUser?.isStaff &&
                    <Button className={styles.editButton} type='default' onClick={onUserEdit}>
                        <EditOutlined />
                    </Button>
                }
            </div>

            <div className={styles.content}>
                <div className={styles.bio} style={isSelf ? { marginTop: 28 } : {}}>
                    {currentViewedMember?.bio}
                </div>
                {currentViewedMember?.company &&
                    <div>
                        <SubHeader title={'Company'} />
                        {currentViewedMember.company?.id ?
                            <CompanyInfo onCompanyClicked={onCompanyClicked} companyModel={currentViewedMember?.company} />
                            :
                            <div>{currentViewedMember.company.name}</div>
                        }
                    </div>
                }

                {currentViewedMember?.cohort &&
                    <div className={styles.location}>
                        <SubHeader title={'Cohort'} />
                        <div>{currentViewedMember.cohort}</div>
                    </div>
                }

                {currentViewedMember?.location &&
                    <div className={styles.location}>
                        <SubHeader title={'Location'} />
                        <div style={{ marginTop: -10 }}>
                            {!isWindows ?
                                <span>
                                    {flag(currentViewedMember.location.countryCode)}
                                </span>
                                :
                                <span>
                                    {currentViewedMember.location.flagIcon && <img src={currentViewedMember.location.flagIcon} />}
                                </span>
                            }
                            <span style={{ marginLeft: 4 }}>{currentViewedMember.location.country}</span>
                        </div>
                    </div>
                }
                {currentViewedMember?.badges &&
                    <div>
                        <SubHeader title={'Badges'} />
                        <div className={styles.badges}>
                            {(currentViewedMember?.badges?.admin || currentViewedMember?.badges?.moderator) &&
                                <div className={styles.badge}>
                                    <img src={moderatorBadge} />
                                    <div>Manager</div>
                                </div>
                            }
                            {currentViewedMember?.badges?.foundingMember &&
                                <div className={styles.badge}>
                                    <img src={foundingMemberBadge} />
                                    <div>Founding Member</div>
                                </div>
                            }
                        </div>
                    </div>
                }

            </div>
            <MeetMemberModal visible={meetMemberModalVisible} onCancel={() => setMeetMemberModalVisible(false)} />
            <RequestIntroModal visible={requestIntroModalVisible} onCancel={() => setRequesIntroModalVisible(false)} />
            <MemberMetricsModal visible={memberMetricsVisible} onDismiss={() => setMemberMetricsVisible(false)} />
            {userToEdit && <EditUserModal visible={userToEdit ? true : false}
                onCancel={() => setUserToEdit(undefined)}
                onFinish={() => window.location.reload()}
                userModel={userToEdit} />}
        </Drawer>
    )
}

const SubHeader = ({ title }: { title: string }) => {
    return <div className={styles.subHeader}>
        {title}
    </div>
}

export default inject('membersStore', 'viewStateStore', 'sessionStore')(observer(UserDrawer));
